import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import validations from "assets/js/validations";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";

import Select from "@material-ui/core/Select";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";

import TextField from "@material-ui/core/TextField";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const CourseSessionDiscount = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setColumns([
      {
        title: "Pourcentage",
        field: "rate",
        render: data => data.rate + " %",
        editComponent: props => (
          <TextField
            type="text"
            style={{ width: "100%" }}
            value={props.value}
            variant="outlined"
            label="Pourcentage"
            onChange={e => props.onChange(e.target.value)}
          />
        ),
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: "Commence le",
        field: "start_at",
        render: data => moment.utc(data.start_at).format("LLL"),
        editComponent: props => (
          <DateTimePicker
            ampm={false}
            style={{ width: "100%" }}
            label="Commence le"
            inputVariant="outlined"
            value={props.value ? moment.utc(props.value) : null}
            onChange={e => props.onChange(e)}
          />
        ),
        cellStyle: {
          width: 250,
          maxWidth: 250
        },
        headerStyle: {
          width: 250,
          maxWidth: 250
        }
      },
      {
        title: "Términe le",
        field: "end_at",
        render: data => moment.utc(data.end_at).format("LLL"),
        editComponent: props => (
          <DateTimePicker
            ampm={false}
            style={{ width: "100%" }}
            label="Términe le"
            inputVariant="outlined"
            value={props.value ? moment.utc(props.value) : null}
            onChange={e => props.onChange(e)}
          />
        ),
        cellStyle: {
          width: 250,
          maxWidth: 250
        },
        headerStyle: {
          width: 250,
          maxWidth: 250
        }
      },
      {
        title: "Promo",
        field: "deal",
        initialEditValue: false,
        render: data =>
          data.deal ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        editComponent: props => (
          <Switch
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value !== undefined ? props.value : true}
            color="primary"
          />
        ),
        cellStyle: {
          width: 80,
          maxWidth: 80
        },
        headerStyle: {
          width: 80,
          maxWidth: 80
        }
      },
      {
        title: "Active",
        field: "active",
        initialEditValue: true,
        render: data =>
          data.active ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        editComponent: props => (
          <Switch
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value !== undefined ? props.value : true}
            color="primary"
          />
        ),
        cellStyle: {
          width: 80,
          maxWidth: 80
        },
        headerStyle: {
          width: 80,
          maxWidth: 80
        }
      }
    ]);

    API.get("sessions/" + props.session.id + "/discounts")
      .then(function(res) {
        setLoading(false);
        setData(res.data);
      })
      .catch(e => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {ability.can("list", "sessionsDiscounts") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto"
              }}
            />
          ) : (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <MaterialTable
                title={"Promotions"}
                columns={columns}
                data={data}
                defaultSorted={[
                  {
                    id: "title",
                    desc: true
                  }
                ]}
                editable={{
                  onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                      console.log("newData", newData);
                      if (
                        newData.rate.length !== "" &&
                        newData.start_at.length !== "" &&
                        newData.end_at.length !== ""
                      ) {
                        newData.start_at = moment(newData.start_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        newData.end_at = moment(newData.end_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        API.post(
                          "sessions/" + props.session.id + "/discounts",
                          {
                            ...newData,
                            vat_id: 1
                          }
                        )
                          .then(res => {
                            const editedData = JSON.parse(JSON.stringify(data));
                            editedData.push(res.data);
                            if(newData.deal){
                              editedData.map((d, i) => {
                                if(d.id !== res.data.id) editedData[i]['deal'] = false
                              })
                            }
                            setData(editedData);
                          })
                          .catch(e => {
                            dispatch({ type: "CATCH_ERROR", payload: e });
                          })
                          .finally(() => {
                            resolve();
                            setLoading(false);
                          });
                      } else {
                        reject();
                        alert(validations.allFieldsRequired);
                      }
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      if (
                        newData.rate !== "" &&
                        newData.start_at !== "" &&
                        newData.end_at !== ""
                      ) {

                        //newData.vat_id = 1;
                        newData.start_at = moment(newData.start_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        newData.end_at = moment(newData.end_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        API.put("discounts/" + oldData.id, newData)
                          .then(() => {
                            const editedData = JSON.parse(JSON.stringify(data));
                            const index = data.indexOf(oldData);
                            editedData[index] = newData;
                            if(newData.deal){
                              editedData.map((d, i) => {
                                if(d.id !== oldData.id) editedData[i]['deal'] = false
                              })
                            }
                            setData(editedData);
                          })
                          .catch(e => {
                            dispatch({ type: "CATCH_ERROR", payload: e });
                          })
                          .finally(() => {
                            setLoading(false);
                            resolve();
                          });
                      } else {
                        reject();
                        alert(validations.allFieldsRequired);
                        /**
                  dispatch({
                    type: "OPEN_SNACKBAR",
                    payload: {
                      type: "error",
                      content: validations.allFieldsRequired
                    }
                  });
                  */
                      }
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      API.delete("discounts/" + oldData.id)
                        .then(() => {
                          setData(data.filter(row => row.id !== oldData.id));
                          resolve();
                        })
                        .catch(e => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    })
                }}
                options={{
                  exportButton: true
                }}
                icons={{
                  SortArrow: forwardRef((props, ref) => (
                    <KeyboardArrowDown {...props} ref={ref} />
                  ))
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "Lignes",
                    labelRowsPerPage: "Ligne par page",
                    firstAriaLabel: "Première page",
                    firstTooltip: "Première page",
                    previousAriaLabel: "Page précédente",
                    previousTooltip: "Page précédente",
                    nextAriaLabel: "Page suivante",
                    nextTooltip: "Page suivante",
                    lastAriaLabel: "Dernière page",
                    lastTooltip: "Dernière page"
                  },
                  toolbar: {
                    exportTitle: "Exporter",
                    exportName: "Exporter en format CSV",
                    searchTooltip: "Recherche",
                    searchPlaceholder: "Recherche"
                  },
                  header: {
                    actions: "Actions"
                  },
                  body: {
                    addTooltip: "Ajouter",
                    deleteTooltip: "Supprimer",
                    editTooltip: "Modifier",
                    emptyDataSourceMessage: "Aucun résultat trouvé",
                    filterRow: {
                      filterTooltip: "Filter"
                    },
                    editRow: {
                      deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                      cancelTooltip: "Annuler",
                      saveTooltip: "Sauvegarder"
                    }
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default CourseSessionDiscount;
