import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";

import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    width: "100%",
  },
}));

const SiteForm = (props) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [site, setSite] = useState({
    site: "",
    country: "",
    lang: "",
    phone1: "",
    phone2: null,
    sales_mail: "",
    support_mail: "",
    gtag: "",
    price_symbol: "+",
    price_percent: "",
    whatsapp1: "",
    whatsapp2: null,
    image: "",
  });
  const [payments, setPayments] = useState(props.payments);
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setSite(props.site);
  }, []);

  const handleChange = (event) => {
    setSite({
      ...site,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setSite({
      ...site,
      image: event.target.files[0],
    });
  };

  const handleEditorChange = (content, editor) => {
    setSite({
      ...site,
      description: content,
    });
  };

  const handleCheckChange = (event) => {
    setSite({
      ...site,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Update the formData object
    const formData = new FormData();

    for (var key in site) {
      formData.append(key, site[key]);
    }

    if (props.action === "put") {
      formData.append("_method", "put");
    }
    let id = props.action === "put" ? "/" + site.id : "";
    const options = {
      method: "POST",
      data: formData,
      url: "sites" + id,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content: content,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextValidator
            label="Site (www.bcloud.tld)"
            className={classes.textField}
            onChange={handleChange}
            name="site"
            value={site.site}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Pays"
            onChange={handleChange}
            name="country"
            value={site.country}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="LangIso (fr-MA)"
            onChange={handleChange}
            name="lang"
            value={site.lang}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Premier téléphone"
            onChange={handleChange}
            name="phone1"
            value={site.phone1}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Deuxiéme téléphone"
            onChange={handleChange}
            name="phone2"
            value={site.phone2}
            variant="outlined"
            margin="dense"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            onChange={handleFileChange}
            name="image"
            type="file"
            variant="outlined"
            margin="dense"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Sales Email"
            onChange={handleChange}
            name="sales_mail"
            value={site.sales_mail}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Support Email"
            onChange={handleChange}
            name="support_mail"
            value={site.support_mail}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Gtag"
            onChange={handleChange}
            name="gtag"
            value={site.gtag}
            variant="outlined"
            margin="dense"
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={4} md={2}>
          <SelectValidator
            className={classes.textField}
            fullWidth
            name="percent_symbol"
            value={site.percent_symbol}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          >
            <MenuItem key="+" value="+">
              Plus
            </MenuItem>
            <MenuItem key="-" value="-">
              Moins
            </MenuItem>
          </SelectValidator>
        </Grid>

        <Grid item xs={8} md={4}>
          <TextValidator
            className={classes.textField}
            label="Pourcentage Prix (%)"
            onChange={handleChange}
            name="price_percent"
            value={site.price_percent}
            variant="outlined"
            margin="dense"
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Premier Whatsapp"
            onChange={handleChange}
            name="whatsapp1"
            value={site.whatsapp1}
            variant="outlined"
            margin="dense"
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Deuxiéme Whatsapp"
            onChange={handleChange}
            name="whatsapp2"
            value={site.whatsapp2}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>

        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default SiteForm;
