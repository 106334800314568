import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextEditor from "components/TextEditor/TextEditor.js";
import Cookie from "js-cookie";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    width: "100%",
  },
}));

const ELearningForm = (props) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [eLearning, setELearning] = useState({
    lang_iso: Cookie.get("lang") ?? "fr",
    title: "",
    permalink: "",
    meta_title: "",
    image: null,
    meta_description: "",
    short_description: "",
    description: "",
    category_id: "",
    language: "FR",
    visits: "",
    price: "",
    price_base: "",
    vat_id: 1,
    active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setELearning(props.eLearning);
  }, []);

  const handleChange = (event) => {
    setELearning({
      ...eLearning,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setELearning({
      ...eLearning,
      image: event.target.files[0],
    });
  };

  const handleEditorChange = (content, editor) => {
    setELearning((prevElearning) => ({
      ...prevElearning,
      description: content,
    }));
  };

  const handleCheckChange = (event) => {
    setELearning({
      ...eLearning,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Update the formData object
    const formData = new FormData();

    for (var key in eLearning) {
      formData.append(key, eLearning[key]);
    }

    if (props.action === "put") {
      formData.append("_method", "put");
    }
    let id = props.action === "put" ? "/" + eLearning.id : "";
    const options = {
      method: "POST",
      data: formData,
      url: "eLearning" + id,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content: content,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextValidator
            label="Titre"
            className={classes.textField}
            onChange={handleChange}
            name="title"
            value={eLearning.title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            disabled
            className={classes.textField}
            label="Salesforce ID"
            onChange={handleChange}
            name="permalink"
            value={eLearning.salesforce_id}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Slug (permalink)"
            onChange={handleChange}
            name="permalink"
            value={eLearning.permalink}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Meta title"
            onChange={handleChange}
            name="meta_title"
            value={eLearning.meta_title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            onChange={handleFileChange}
            name="image"
            type="file"
            variant="outlined"
            margin="dense"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            fullWidth
            label="Catégories"
            name="category_id"
            value={eLearning.category_id}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          >
            {props.categories.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.title}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Prix"
            onChange={handleChange}
            name="price"
            value={eLearning.price}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Prix de base"
            onChange={handleChange}
            name="price_base"
            value={eLearning.price_base}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Meta description"
            onChange={handleChange}
            name="meta_description"
            value={eLearning.meta_description}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Courte description"
            multiline
            rows="2"
            onChange={handleChange}
            name="short_description"
            value={eLearning.short_description}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextEditor
            value={eLearning.description}
            handleChange={handleEditorChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Visiteurs"
            onChange={handleChange}
            name="visits"
            value={eLearning.visits}
            variant="outlined"
            margin="dense"
            validators={["required", "isNumber"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="active"
                checked={eLearning.active}
                value={eLearning.active}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Active."
          />
        </Grid>

        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default ELearningForm;
