import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import validations from "assets/js/validations";
import TextField from "@material-ui/core/TextField";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Form from "forms/Voucher";
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const BcloudTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [orders, setOrders] = useState([]);

  const [loading, setLoading] = useState(false);

  const connectOpenCart = (
    fname,
    email,
    cid,
    voucher,
    voucherValue,
    action = "checkout"
  ) => {
    API.post("connect-open-cart", {
      fname,
      email,
      cid,
      voucher,
      voucher_value: voucherValue,
      action,
    })
      .then((res) => {
        if (action == "checkout") {
          window.open(
            `${process.env.REACT_APP_BASE_URL}connect?token=${res.data.token}&cid=${cid}`,
            "_blank"
          );
        } else {
          dispatch({
            type: "OPEN_SNACKBAR",
            payload: {
              type: "success",
              content: validations.notificationSuccessfullySent,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          console.log(e.response.status);
          dispatch({
            type: "OPEN_SNACKBAR",
            payload: {
              type: "error",
              content: validations.loginError,
            },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openCheckoutNotify = (
    event,
    userId,
    fname,
    email,
    cid,
    voucher,
    voucherValue,
    action
  ) => {
    if (ability.can("create", "managers")) {
      if (voucher && voucherValue) {
        connectOpenCart(fname, email, cid, voucher, voucherValue, action);
      } else {
        const pendingOrder = {
          userId,
          fname,
          email,
          cid,
          voucher,
          voucherValue,
          action,
        };
        dispatch({
          type: "OPEN_DRAWER",
          payload: {
            event: event,
            title: "Coupon pour panier",
            content: (
              <Form
                action={"post"}
                email={email}
                pendingOrder={pendingOrder}
                onChange={createVoucher}
              />
            ),
          },
        });
      }
    }
  };

  const createVoucher = (voucher, fname, email, cid, action) => {
    if (ability.can("create", "vouchers")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      connectOpenCart(
        fname,
        email,
        cid,
        voucher.name,
        voucher.formattedValue,
        action
      );
    }
  };

  useEffect(() => {
    setLoading(true);

    setColumns([
      {
        title: "Commande & Client",
        field: "user",
        editable: "never",
        render: (data) => (
          <div>
            <b>Commande Numéro: </b>
            {data.id}
            <br />
            <b>Client: </b>
            {data.user.fname + " " + data.user.lname}
            <br />
            <b>ID BCloud: </b>
            {data.user.id}
            <br />
            <small>{data.user.email}</small>
            <br />
            <small>
              {data.user.function}{" "}
              {data.user.phone ? "(" + data.user.phone + ")" : false}
            </small>
            <br />
            <div>
              <div>
                <b>Total: </b>
                {data.summary.total.formatted}
                {data.coupon !== null ? (
                  <div>
                    <b>
                      <small>Coupon utilisé:</small>
                    </b>{" "}
                    {data.coupon}{" "}
                    <small>(-{data.voucherDetails?.discountedRate})</small>
                  </div>
                ) : null}
              </div>
              <div>
                <b>
                  <small>Date de la commande: </small>
                </b>
                <br />{" "}
                {(data.date = moment(data.date).format("DD/MM/YYYY HH:mm:ss"))}
              </div>
            </div>
          </div>
        ),
        cellStyle: {
          width: 300,
          maxWidth: 300,
          verticalAlign: "top",
        },
        headerStyle: {
          width: 300,
          maxWidth: 300,
        },
      },
      {
        title: "Détails de la commande",
        render: (data) => (
          <div>
            {data.simulators.length > 0 ? <b>Simulateurs: </b> : false}
            {data.simulators.map((s, i) => {
              return <div>{s.title}</div>;
            })}
            <br />
            {data.sessions.length > 0 ? <b>Sessions de formation: </b> : false}
            {data.sessions.map((s, i) => {
              return <div>{s.course.title}</div>;
            })}
            <br />
            {data.certificates.length > 0 ? <b>Certification: </b> : false}
            {data.certificates.map((s, i) => {
              return <div>{s.title}</div>;
            })}
            <br />
            {data.eLearnings.length > 0 ? <b>ELearnings: </b> : false}
            {data.eLearnings.map((e, i) => {
              return <div>{e.title} </div>;
            })}
          </div>
        ),
        editable: "never",
        cellStyle: {
          width: 600,
          maxWidth: 600,
          verticalAlign: "top",
        },
        headerStyle: {
          width: 600,
          maxWidth: 600,
        },
      },
      {
        title: "Note de relance",
        field: "note",
        editComponent: (props) => (
          <TextField
            type="text"
            multiline
            rows="8"
            style={{ width: "100%" }}
            value={props.value}
            variant="outlined"
            label="Note"
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
        render: (data) => (
          <div style={{ color: data.note ? "green" : "red" }}>
            {data.note ? data.note : "Non traité"}
          </div>
        ),
        cellStyle: {
          width: 200,
          maxWidth: 200,
          verticalAlign: "top",
        },
        headerStyle: {
          width: 200,
          maxWidth: 200,
        },
      },
      {
        title: "Actions",
        render: (data) => (
          <>
            <ShoppingBasketOutlinedIcon
              alt="Confirmer & Payer"
              aria-label="add an alarm"
              style={{ color: "green", cursor: "pointer" }}
              onClick={(e) =>
                openCheckoutNotify(
                  e,
                  data.user.id,
                  data.user.fname,
                  data.user.email,
                  data.cid,
                  data.coupon,
                  data.voucherDetails?.discountedRate,
                  "checkout"
                )
              }
            />
            <br />
            <MailOutlineIcon
              style={{ color: "green", cursor: "pointer" }}
              onClick={(e) =>
                openCheckoutNotify(
                  e,
                  data.user.id,
                  data.user.fname,
                  data.user.email,
                  data.cid,
                  data.coupon,
                  data.voucherDetails?.discountedRate,
                  "notify"
                )
              }
            />
          </>
        ),
        cellStyle: {
          width: 90,
          maxWidth: 90,
          verticalAlign: "top",
        },
        headerStyle: {
          width: 90,
          maxWidth: 90,
        },
      },
    ]);
    if (ability.can("list", "orders")) {
      API.get("pending-orders")
        .then(function(res) {
          setLoading(false);
          setOrders(res.data);
        })
        .catch((e) => {
          dispatch({ type: "CATCH_ERROR", payload: e });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      {ability.can("list", "orders") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <>
              <MaterialTable
                title={null}
                columns={columns}
                data={orders}
                options={{ pageSize: 1, pageSizeOptions: [1, 2, 5, 10, 20] }}
                defaultSorted={[
                  {
                    id: "id",
                    desc: true,
                  },
                ]}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      API.put("orders/" + oldData.id + "/pending-note", {
                        note: newData.note,
                      })
                        .then(() => {
                          const editedData = JSON.parse(JSON.stringify(orders));
                          const index = orders.indexOf(oldData);
                          editedData[index] = newData;
                          setOrders(editedData);

                          dispatch({
                            type: "OPEN_SNACKBAR",
                            payload: {
                              type: "success",
                              content: validations.successfullyUpdated,
                            },
                          });
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                        })
                        .finally(() => {
                          setLoading(false);
                          resolve();
                        });
                    }),
                }}
                icons={{
                  SortArrow: forwardRef((props, ref) => (
                    <KeyboardArrowDown {...props} ref={ref} />
                  )),
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "Lignes",
                    labelRowsPerPage: "Ligne par page",
                    firstAriaLabel: "Première page",
                    firstTooltip: "Première page",
                    previousAriaLabel: "Page précédente",
                    previousTooltip: "Page précédente",
                    nextAriaLabel: "Page suivante",
                    nextTooltip: "Page suivante",
                    lastAriaLabel: "Dernière page",
                    lastTooltip: "Dernière page",
                  },
                  toolbar: {
                    exportTitle: "Exporter",
                    exportName: "Exporter en format CSV",
                    searchTooltip: "Recherche",
                    searchPlaceholder: "Recherche",
                  },
                  header: {
                    actions: "Action",
                  },
                  body: {
                    addTooltip: "Ajouter",
                    deleteTooltip: "Supprimer",
                    editTooltip: "Ajouter une note",
                    emptyDataSourceMessage: "Aucun résultat trouvé",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                    editRow: {
                      deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                      cancelTooltip: "Annuler",
                      saveTooltip: "Sauvegarder",
                    },
                  },
                }}
              />
              <br />
              <div style={{ display: "flex", alignItems: "center" }}>
                <ShoppingBasketOutlinedIcon
                  fontSize="small"
                  style={{ color: "green" }}
                />
                Creation d'un voucher si existe pas et redirection vers le
                panier du client
              </div>
              <br />
              <div style={{ display: "flex", alignItems: "center" }}>
                <MailOutlineIcon fontSize="small" style={{ color: "green" }} />{" "}
                Creation d'un voucher si existe pas et l'envoi d'un mail de
                relance
              </div>
            </>
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default BcloudTable;
