import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";
import moment from "moment";
import Cookie from "js-cookie";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "450px",
  },
  textField: {
    width: "100%",
  },
}));

const VoucherForm = (props) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.toUpperCase();
  };

  const [voucher, setVoucher] = useState({
    lang_iso: Cookie.get("lang") ?? "fr",
    name: makeid(10),
    user_id: props.pendingOrder?.userId ?? "",
    product_id: "",
    type: "",
    value: "",
    min_purchase: "",
    short_description: "",
    unique_usage: true,
    used: false,
    expire: "",
    created_at: "",
    active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setVoucher(props.voucher);
  }, []);

  const handleChange = (event) => {
    setVoucher({
      ...voucher,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (expire) => {
    setVoucher({
      ...voucher,
      expire: expire,
    });
  };

  const handleCheckChange = (event) => {
    setVoucher({
      ...voucher,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let id = props.action === "put" ? "/" + voucher.id : "";
    voucher.expire = moment(voucher.expire).format("YYYY-MM-DD HH:mm:ss");
    const options = {
      method: props.action,
      data: voucher,
      url: "vouchers" + id,
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(
          res.data,
          props.pendingOrder?.fname,
          props.pendingOrder?.email,
          props.pendingOrder?.cid,
          props.pendingOrder?.action
        );
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: { type: "success", content: content },
        });
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextValidator
            label="Nom du coupon *"
            className={classes.textField}
            onChange={handleChange}
            name="name"
            value={voucher.name}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            label="Utilisateur"
            className={classes.textField}
            onChange={handleChange}
            name="user_id"
            helperText={
              "Récupérer l'ID du client via la recherche du module Clients"
            }
            disabled={props.pendingOrder}
            value={voucher.user_id}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            label="Produit (Formation, certification, simulateur)"
            className={classes.textField}
            onChange={handleChange}
            name="product_id"
            helperText={
              "Récupérer l'ID salesforce du produit via la recherche catalogue"
            }
            value={voucher.product_id}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            multiple
            fullWidth
            label="Type *"
            name="type"
            value={voucher.type}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            SelectProps={{
              multiple: false,
            }}
            errorMessages={[validations.required]}
          >
            <MenuItem value="fix">Montant fixe</MenuItem>
            <MenuItem value="percent">Pourcentage</MenuItem>
          </SelectValidator>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Valeur *"
            onChange={handleChange}
            name="value"
            value={voucher.value}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Minimum d'achat *"
            onChange={handleChange}
            validators={["required"]}
            name="min_purchase"
            value={voucher.min_purchase}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              ampm={false}
              style={{ width: "100%" }}
              label="Expire le *"
              inputVariant="outlined"
              value={voucher.expire ? moment.utc(voucher.expire) : null}
              onChange={handleDateChange}
              validators={["required"]}
              errorMessages={[validations.required]}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="unique_usage"
                checked={voucher.unique_usage}
                value={voucher.unique_usage}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Utilisation unique *"
          />
          <br />
          <span>
            <small>
              {
                "Si vous cocher cette case Le coupon sera utilisé une seule fois."
              }
            </small>
          </span>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="active"
                checked={voucher.active}
                value={voucher.active}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Active *"
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default VoucherForm;
