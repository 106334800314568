import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Rating from "@material-ui/lab/Rating";
import TextEditor from "components/TextEditor/TextEditor.js";
import Cookie from "js-cookie";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";
import { withStyles } from "@material-ui/core/styles";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ffb400",
    fontSize: "24px",
  },
  iconHover: {
    color: "#ffb400",
    fontSize: "24px",
  },
  iconEmpty: {
    color: "rgba(0, 0, 0, 0.26)",
    fontSize: "24px",
  },
})(Rating);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "450px",
  },
  textField: {
    width: "100%",
  },
}));

const CertificationForm = (props) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [simulators, setSimulators] = useState([])
  const [loadingSimulators, setLoadingSimulators] = useState(false)
  const [certification, setCertification] = useState({
    lang_iso: Cookie.get("lang") ?? "fr",
    title: "",
    permalink: "",
    meta_title: "",
    image: null,
    meta_description: "",
    short_description: "",
    description: "",
    category_id: "",
    language: "FR",
    price_base: "",
    price: "",
    visits: "",
    rating: 5,
    evaluation: "",
    duration: "",
    simulator: false,
    take2: false,
    take2_price: "",
    discount_description: "",
    offered_simulator: "",
    vat_id: 1,
    prerequisites: false,
    active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setCertification(props.certif);

    const fetchData = async () => {
      API.get(`simulators`)
        .then(function(res) {
            setLoadingSimulators(false);
            setSimulators(res.data);
            console.log("simulators", res.data)
        })
        .catch((e) => {
            dispatch({ type: "CATCH_ERROR", payload: e });
        })
        .finally(() => {
            setLoadingSimulators(false);
        });
    
    };
    fetchData();

  }, []);

  const handleChange = (event) => {
    setCertification({
      ...certification,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setCertification({
      ...certification,
      image: event.target.files[0],
    });
  };

  const handleCheckChange = (event) => {
    setCertification({
      ...certification,
      [event.target.name]: event.target.checked,
    });
  };

  const handleEditorChange = (content, editor) => {
    setCertification((prevCertification) => ({
      ...prevCertification,
      description: content,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Update the formData object
    const formData = new FormData();

    for (var key in certification) {
      formData.append(key, certification[key]);
    }

    if (props.action === "put") {
      formData.append("_method", "put");
    }
    let id = props.action === "put" ? "/" + certification.id : "";
    const options = {
      method: "POST",
      data: formData,
      url: "certifs" + id,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };


    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content: content,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <TextValidator
            label="Titre"
            className={classes.textField}
            onChange={handleChange}
            name="title"
            value={certification.title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Slug (permalink)"
            onChange={handleChange}
            name="permalink"
            value={certification.permalink}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Meta title"
            onChange={handleChange}
            name="meta_title"
            value={certification.meta_title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            onChange={handleFileChange}
            name="image"
            type="file"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Prix de base"
            onChange={handleChange}
            value={certification.price_base}
            name="price_base"
            variant="outlined"
            margin="dense"
            validators={["required", "isFloat"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Prix final"
            onChange={handleChange}
            name="price"
            value={certification.price}
            variant="outlined"
            margin="dense"
            validators={["required", "isFloat"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>

        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Meta description"
            onChange={handleChange}
            name="meta_description"
            value={certification.meta_description}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Courte description"
            onChange={handleChange}
            name="short_description"
            value={certification.short_description}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextEditor
            value={certification.description}
            handleChange={handleEditorChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            fullWidth
            label="Catégories"
            name="category_id"
            value={certification.category_id}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          >
            {props.categories.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.title}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Visiteurs"
            onChange={handleChange}
            name="visits"
            value={certification.visits}
            variant="outlined"
            margin="dense"
            validators={["required", "isNumber"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Évaluation"
            onChange={handleChange}
            name="evaluation"
            value={certification.evaluation}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Durée du certif (En heure)"
            onChange={handleChange}
            name="duration"
            value={certification.duration}
            variant="outlined"
            margin="dense"
            validators={["required", "isNumber"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Description Remise"
            onChange={handleChange}
            name="discount_description"
            value={certification.discount_description}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12}>
          <StyledRating
            name="rating"
            value={certification.rating}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                name="take2"
                checked={certification.take2}
                value={certification.take2}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Take2."
          />
        </Grid>

        {certification.take2 && <>
        
        <Grid item xs={6} md={6}>
          <TextValidator
            className={classes.textField}
            label="Prix du Take2"
            onChange={handleChange}
            name="take2_price"
            value={certification.take2_price}
            variant="outlined"
            margin="dense"
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>
        </>}


        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                name="simulator"
                checked={certification.simulator}
                value={certification.simulator}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Simulator offert."
          />
        </Grid>

        {certification.simulator && <>
        
        <Grid item xs={6} md={6}>
          <TextValidator
            className={classes.textField}
            label="Nom du simulateur"
            onChange={handleChange}
            name="offered_simulator"
            value={certification.offered_simulator}
            variant="outlined"
            margin="dense"
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>
        </>}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="prerequisites"
                checked={certification.prerequisites}
                value={certification.prerequisites}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Prérequis."
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                name="active"
                checked={certification.active}
                value={certification.active}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Active."
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default CertificationForm;
