import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Form from "forms/Currency";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const CurrenciesTable = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [actions, setActions] = useState([]);

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    setColumns([
      {
        title: "Code",
        field: "code",
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: "Symbole",
        field: "symbol",
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: "Taux",
        field: "rate",
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: "Défaut",
        field: "default",
        render: data =>
          data.default ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 80,
          maxWidth: 80
        },
        headerStyle: {
          width: 80,
          maxWidth: 80
        }
      },
      {
        title: "Active",
        field: "active",
        initialEditValue: true,
        render: data =>
          data.active ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 80,
          maxWidth: 80
        },
        headerStyle: {
          width: 80,
          maxWidth: 80
        }
      }
    ]);

    if (ability.can("list", "currencies")) {
      API.get("currencies")
        .then(function(res) {
          setLoading(false);
          setCurrencies(res.data);
        })
        .catch(e => {
          dispatch({ type: "CATCH_ERROR", payload: e });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const addItem = event => {
    if (ability.can("create", "currencies")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouvelle devise",
          content: <Form action={"post"} onChange={createCurrency} />
        }
      });
    }
  };

  const createCurrency = currency => {
    if (ability.can("create", "currencies")) {
      dispatch({
        type: "CLOSE_DRAWER"
      });
      let newCurrencies = JSON.parse(JSON.stringify(currencies));
      newCurrencies.unshift(currency);
      setCurrencies(newCurrencies);
    }
  };

  const updateCurrency = currency => {
    if (ability.can("update", "currencies")) {
      dispatch({
        type: "CLOSE_DRAWER"
      });
      let currencyIndex = currencies.findIndex(c => c.code == currency.code);
      let newCurrencies = JSON.parse(JSON.stringify(currencies));
      newCurrencies[currencyIndex] = currency;

      if (currency.default && currency.active) {
        newCurrencies.map((c, i) => {
          console.log(currencyIndex, i);

          if (i != currencyIndex) newCurrencies[i]["default"] = false;
        });
      }

      console.log("newCurrencies", newCurrencies);

      setCurrencies(newCurrencies);
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "currencies")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Modification de la devise " + rowData.code,
          content: (
            <Form action={"put"} currency={rowData} onChange={updateCurrency} />
          )
        }
      });
    }
  };
  return (
    <>
      {ability.can("list", "currencies") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto"
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              columns={columns}
              data={currencies}
              defaultSorted={[
                {
                  id: "title",
                  desc: true
                }
              ]}
              editable={{
                onRowDelete: oldData => {
                  if (ability.can("delete", "currencies")) {
                    new Promise((resolve, reject) => {
                      if (oldData.default && oldData.active) {
                        alert(
                          "Vous ne pouvez pas supprimer la devise par defaut"
                        );
                        resolve();
                      } else
                        API.delete("currencies/" + oldData.code)
                          .then(() => {
                            setCurrencies(
                              currencies.filter(c => c.code !== oldData.code)
                            );
                          })
                          .catch(e => {
                            dispatch({ type: "CATCH_ERROR", payload: e });
                            reject();
                          })
                          .finally(() => {
                            resolve();
                          });
                    });
                  }
                }
              }}
              actions={[
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: event => addItem(event)
                },
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData)
                }
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                ))
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page"
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche"
                },
                header: {
                  actions: "Actions"
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter"
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder"
                  }
                }
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default CurrenciesTable;
