import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Form from "forms/Role";
import PermissionsForm from "forms/Permissions";

import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const RolesPermissionsTable = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [roles, setRoles] = useState([]);
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ count, setCount ] = useState(0)

  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    setColumns([
      {
        title: "ID",
        field: "id",
        cellStyle: {
          width: 10,
          maxWidth: 10
        },
        headerStyle: {
          width: 10,
          maxWidth: 10
        }
      },
      {
        title: "Permissions",
        render: data => (
          <IconButton
            onClick={() => openPermissionsDrawer(data)}
            component="div"
          >
            <LockOpenIcon />
          </IconButton>
        ),
        sorting: false,
        cellStyle: {
          width: 80,
          maxWidth: 80
        },
        headerStyle: {
          width: 80,
          maxWidth: 80
        }
      },
      {
        title: "Nom",
        field: "display_name",
        cellStyle: {
          width: 250,
          maxWidth: 250
        },
        headerStyle: {
          width: 250,
          maxWidth: 250
        }
      },
      {
        title: "Active",
        field: "active",
        initialEditValue: true,
        render: data =>
          data.active ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 80,
          maxWidth: 80
        },
        headerStyle: {
          width: 80,
          maxWidth: 80
        }
      }
    ]);
    if (ability.can("list", "roles")) {
      API.get("roles")
        .then(function(res) {
          setLoading(false);
          setRoles(res.data);
        })
        .catch(e => {
          dispatch({ type: "CATCH_ERROR", payload: e });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);


  
  const addItem = event => {
    if (ability.can("create", "roles")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouveau rôle",
          content: <Form action={"post"} onChange={createRole} />
        }
      });
    }
  };

  const openPermissionsDrawer = role => {
    if (ability.can("update", "roles")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          width: "full",
          title: "Permissions du rôle " + role.display_name,
          content: (
            <PermissionsForm
              action={"put"}
              role={role}
              onChange={updatePermissions}
            />
          )
        }
      });
    }
  };

  const updatePermissions = () => {
    if (ability.can("update", "roles")) {
      dispatch({
        type: "CLOSE_DRAWER"
      });
    }
  };
  const createRole = role => {
    if (ability.can("create", "roles")) {
      dispatch({
        type: "CLOSE_DRAWER"
      });
      console.log("roooooooles", roles)
      let newRoles = JSON.parse(JSON.stringify(roles));
      newRoles.push(role);
      setRoles(newRoles);
    }
  };

  const updateRole = role => {
    if (ability.can("update", "roles")) {
      dispatch({
        type: "CLOSE_DRAWER"
      });
      let roleIndex = roles.findIndex(c => c.id == role.id);
      let newRoles = JSON.parse(JSON.stringify(roles));
      newRoles[roleIndex] = role;

      setRoles(newRoles);
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "roles")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: rowData.display_name,
          content: <Form action={"put"} role={rowData} onChange={updateRole} />
        }
      });
    }
  };

  return (
    <>
      {ability.can("list", "roles") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto"
              }}
            />
          ) : (
            <>
              <MaterialTable
                title={null}
                columns={columns}
                data={roles}
                defaultSorted={[
                  {
                    id: "title",
                    desc: true
                  }
                ]}
                editable={ability.can("delete", "roles") && {
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      API.delete("roles/" + oldData.id)
                        .then(() => {
                          let data = JSON.parse(JSON.stringify(roles));
                          data = data.filter(c => c.id !== oldData.id);
                          setRoles(data);
                          resolve();
                        })
                        .catch(e => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    })
                }}
                options={{
                  exportButton: true
                }}
                actions={[
                  {
                    icon: "add",
                    isFreeAction: true,
                    onClick: event => addItem(event)
                  },
                  {
                    icon: "edit",
                    tooltip: "Modifier",
                    onClick: (event, rowData) => editItem(event, rowData)
                  }
                ]}
                icons={{
                  SortArrow: forwardRef((props, ref) => (
                    <KeyboardArrowDown {...props} ref={ref} />
                  ))
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsSelect: "Lignes",
                    labelRowsPerPage: "Ligne par page",
                    firstAriaLabel: "Première page",
                    firstTooltip: "Première page",
                    previousAriaLabel: "Page précédente",
                    previousTooltip: "Page précédente",
                    nextAriaLabel: "Page suivante",
                    nextTooltip: "Page suivante",
                    lastAriaLabel: "Dernière page",
                    lastTooltip: "Dernière page"
                  },
                  toolbar: {
                    exportTitle: "Exporter",
                    exportName: "Exporter en format CSV",
                    searchTooltip: "Recherche",
                    searchPlaceholder: "Recherche"
                  },
                  header: {
                    actions: "Actions"
                  },
                  body: {
                    addTooltip: "Ajouter",
                    deleteTooltip: "Supprimer",
                    editTooltip: "Modifier",
                    emptyDataSourceMessage: "Aucun résultat trouvé",
                    filterRow: {
                      filterTooltip: "Filter"
                    },
                    editRow: {
                      deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                      cancelTooltip: "Annuler",
                      saveTooltip: "Sauvegarder"
                    }
                  }
                }}
              />
            </>
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default RolesPermissionsTable;
