import React, { useEffect, forwardRef, useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";

const bulkCss = {
  input: {
    display: "none",
  },
  upload: {
    marginRight: 20,
  },
  previewText: {
    fontSize: 20,
    fontWeight: 500,
    marginTop: 14,
  },
  previewExample: {
    marginTop: 14,
    width: 600,
  },
  preview: {
    fontSize: 20,
    fontWeight: 500,
    marginTop: 14,
    marginBottom: 10,
  },
  question: {
    fontSize: 18,
    marginTop: 8,
    fontWeight: 400,
  },
  answer: {
    fontSize: 14,
    marginTop: 8,
    marginLeft: 24,
  },
  correct: {
    color: "green",
  },
  notCorrect: {
    color: "red",
  },
  //   explanation: {
  //     fontSize: 14,
  //     marginTop: 4,
  //   },
  questionType: {
    fontSize: 14,
    fontWeight: 400,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "600px",
  },
  textField: {
    width: "100%",
  },
};

const inputCssStyles = makeStyles(bulkCss);

const SimulatorBulkExercices = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [data, setData] = useState([]);
  const [exercice, setExercice] = useState({
    title: "",
    description: "",
  });
  const bulkClasses = inputCssStyles();

  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setExercice({
      ...exercice,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("file", event.target.files[0]);

    const options = {
      method: "POST",
      data: formData,
      url: "simulators/" + props.simulator.id + "/exercices/bulkImport",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const content = validations.processFinished;

    API(options)
      .then(function(res) {
        setData(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmPDF = (event) => {
    const contentConfirmed = validations.successfullyAdded;

    API.post("simulators/" + props.simulator.id + "/exercices/bulkConfirm", {
      exercice: data,
      title: exercice.title,
      description: exercice.description,
    })
      .then((res) => {
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            contentConfirmed,
          },
        });
        dispatch({
          type: "CLOSE_DRAWER",
        });
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const AnswersPreview = ({ answers }) => {
    console.log("ansqwers", answers);
    return (
      answers.length > 0 &&
      answers.map((answer) => (
        <div className={bulkClasses.answer}>
          {answer.title} -{" "}
          {answer.correct ? (
            <span className={bulkClasses.correct}>Vrai</span>
          ) : (
            <span className={bulkClasses.notCorrect}>Faux</span>
          )}
        </div>
      ))
    );
  };

  const QuestionPreview = ({ item }) => {
    return (
      <>
        <div className={bulkClasses.question}>
          {item.question}
          <span className={bulkClasses.questionType}>
            (réponse: {item.type})
          </span>
        </div>
        {/* <div className={bulkClasses.explanation}>
          Explication: {item.explanation}
        </div> */}

        <AnswersPreview answers={item.answers} />
      </>
    );
  };

  return (
    <>
      {ability.can("list", "simulatorExercices") ? (
        <>
          <ValidatorForm
            onSubmit={confirmPDF}
            className={bulkClasses.container}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  label="Titre"
                  onChange={handleChange}
                  className={bulkClasses.textField}
                  name="title"
                  variant="outlined"
                  margin="dense"
                  value={exercice.title}
                  validators={["required"]}
                  errorMessages={[validations.required]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  label="Description"
                  onChange={handleChange}
                  className={bulkClasses.textField}
                  multiLine
                  rows="2"
                  name="description"
                  variant="outlined"
                  margin="dense"
                  value={exercice.description}
                  validators={["required"]}
                  errorMessages={[validations.required]}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="application/pdf,application/vnd.ms-excel"
                  className={bulkClasses.input}
                  id={`contained-button`}
                  type="file"
                  name={"name"}
                  onChange={(e) => handleFileChange(e, "desktop")}
                />
                <label
                  htmlFor={`contained-button`}
                  className={bulkClasses.upload}
                >
                  <Button
                    variant="contained"
                    color={"primary"}
                    component="span"
                  >
                    Selectionner votre PDF
                  </Button>
                </label>

                <AsyncButton
                  type="submit"
                  loading={loadingConfirm}
                  title={"Confirmer l'envoi"}
                  color="secondary"
                  disabled={data.length === 0}
                ></AsyncButton>
              </Grid>
            </Grid>
          </ValidatorForm>
          <div>
            {data.length > 0 ? (
              <>
                <div className={bulkClasses.preview}>
                  Aperçu avant confirmation :{" "}
                </div>
                <p>
                  {data.map(
                    (item) => data.length > 0 && <QuestionPreview item={item} />
                  )}
                </p>
              </>
            ) : (
              <div>
                <div className={bulkClasses.previewText}>
                  Veuillez respecter l'exemple ci-dessous :
                </div>
                <img
                  className={bulkClasses.previewExample}
                  src="/bulkExample.png"
                />
              </div>
            )}
          </div>
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default SimulatorBulkExercices;
