import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextEditor from "components/TextEditor/TextEditor.js";
import 'react-quill/dist/quill.snow.css';
import Cookie from "js-cookie";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";
import 'react-quill/dist/quill.bubble.css';


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    width: "100%",
  },
}));

const CourseForm = (props) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [course, setCourse] = useState({
    lang_iso: Cookie.get("lang") ?? "fr",
    title: "",
    permalink: "",
    meta_title: "",
    image: null,
    meta_description: "",
    short_description: "",
    description: "",
    course_program: "",
    why_choose_this: "",
    what_required: "",
    who_should_attend: "",
    categories: [],
    language: "FR",
    visits: "",
    duration: "",
    evaluation: "",
    prerequisites: false,
    included_certif: false,
    certification: false,
    active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setCourse(props.course);
  }, [props.course]);

  const handleChange = (event) => {
    setCourse({
      ...course,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setCourse({
      ...course,
      image: event.target.files[0],
    });
  };
  
  const handleEditorChange = (name, value) => {
    setCourse({
      ...course,
      [name]: value,
    });
  };

  const handleDescriptionChange = (content, editor) => {
    setCourse((prevCourse) => ({
      ...prevCourse,
      description: content,
    }));
  };

  const handleProgramChange = (content, editor) => {
    setCourse((prevCourse) => ({
      ...prevCourse,
      course_program: content,
    }));
  };

  const handleWhatRequiredChange = (content, editor) => {
    setCourse((prevCourse) => ({
      ...prevCourse,
      what_required: content,
    }));
  };

  const handleWhoShouldAttendChange = (content, editor) => {
    setCourse((prevCourse) => ({
      ...prevCourse,
      who_should_attend: content,
    }));
  };

  const handleWhyChooseThisChange = (content, editor) => {
    setCourse((prevCourse) => ({
      ...prevCourse,
      why_choose_this: content,
    }));
  };

  const handleCheckChange = (event) => {
    setCourse((prevCourse) => ({
      ...prevCourse,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Update the formData object
    const formData = new FormData();

    for (var key in course) {
      formData.append(key, course[key]);
    }

    if (props.action === "put") {
      formData.append("_method", "put");
    }
    let id = props.action === "put" ? "/" + course.id : "";
    const options = {
      method: "POST",
      data: formData,
      url: "courses" + id,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content: content,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextValidator
            label="Titre"
            className={classes.textField}
            onChange={handleChange}
            name="title"
            value={course.title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Slug (permalink)"
            onChange={handleChange}
            name="permalink"
            value={course.permalink}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Meta title"
            onChange={handleChange}
            name="meta_title"
            value={course.meta_title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            onChange={handleFileChange}
            name="image"
            type="file"
            variant="outlined"
            margin="dense"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            multiple
            fullWidth
            label="Catégories"
            name="categories"
            value={course.categories}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            SelectProps={{
              multiple: true,
            }}
            errorMessages={[validations.required]}
          >
            {props.categories.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.title}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>

        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Meta description"
            onChange={handleChange}
            name="meta_description"
            value={course.meta_description}
            validators={["required"]}
            max={832}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Courte description"
            multiline
            rows="2"
            onChange={handleChange}
            name="short_description"
            value={course.short_description}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          Description de la formation :
          <TextEditor 
            value={course.description}
            handleChange={handleDescriptionChange}
          />
        </Grid>

        <Grid item xs={12}>
          Programme de la formation :
          <TextEditor 
            value={course.course_program}
            handleChange={handleProgramChange}
          />
        </Grid>

        <Grid item xs={12}>
          Pourquoi choisir cette formation :
          <TextEditor
            value={course.why_choose_this}
            handleChange={handleWhyChooseThisChange}
          />
        </Grid>

        <Grid item xs={12}>
          Pre-requis :
          <TextEditor
            value={course.what_required}
            handleChange={handleWhatRequiredChange}
          />
        </Grid>

        <Grid item xs={12}>
          A qui s'adresse cette formation :
          <TextEditor
            value={course.who_should_attend}
            handleChange={handleWhoShouldAttendChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Visiteurs"
            onChange={handleChange}
            name="visits"
            value={course.visits}
            variant="outlined"
            margin="dense"
            validators={["required", "isNumber"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Évaluation"
            onChange={handleChange}
            name="evaluation"
            value={course.evaluation}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Durée de formation (Jour)"
            onChange={handleChange}
            name="duration"
            value={course.duration}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="included_certif"
                checked={course.included_certif}
                value={course.included_certif}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Prix avec certification."
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="certification"
                checked={course.certification}
                value={course.certification}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Certification."
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="active"
                checked={course.active}
                value={course.active}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Active."
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default CourseForm;
