import React, { useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

import { GlobalContext } from "context/GlobalContext";
import Cookie from "js-cookie";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import TranslateIcon from "@material-ui/icons/Translate";

// @material-ui/icons
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import Menu from "@material-ui/core/Menu";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const { dispatch } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    window.location.href = "/auth/login";
  };

  const changeLanguage = (lang) => {
    Cookie.set("lang", lang);
    dispatch({ type: "SET_LANG", payload: lang });
    window.location.reload();
    setAnchorEl(null);
  };

  const { rtlActive } = props;

  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.buttonLink}
        >
          <TranslateIcon />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => changeLanguage("fr")}>Français</MenuItem>
          <MenuItem onClick={() => changeLanguage("en")}>English</MenuItem>
        </Menu>
      </div>
      <div className={managerClasses}>
        <Hidden smDown implementation="css">
          <Button
            color="transparent"
            justIcon
            aria-label="Déconnexion"
            aria-haspopup="true"
            onClick={handleLogout}
            className={classes.buttonLink}
          >
            <PowerSettingsNew />
          </Button>
        </Hidden>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
