import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";

import { SketchPicker } from "react-color";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";

const containerCss = {
  root: {
    background: "#fff",
    padding: "10px 20px",
    borderRadius: 5,
  },
  item: {
    marginTop: 20,
  },
  day: {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: "Arial",
    marginBottom: 10,
  },
  actions: {
    display: "flex",
    marginTop: "20px",
  },
  image: {
    position: "relative",
  },
  imageDesktop: {
    margin: 1,
  },
  imageMobile: {
    margin: 1,
  },
  title: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  titleDesktop: {
    border: "1px solid #c8c4c4",
    background: "#d5d5d58f",
    padding: "3px 7px",
    borderRadius: "5px",
  },
  titleMobile: {
    marginLeft: 5,
    border: "1px solid #c8c4c4",
    background: "#d5d5d58f",
    padding: "3px 7px",
    borderRadius: "5px",
  },
};

const pickerCss = {
  color: {
    width: "50px",
    height: "33px",
    borderRadius: "2px",
  },
  swatch: {
    marginRight: 20,
    padding: "2px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  },
  popover: {
    position: "absolute",
    zIndex: "999",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
};

const inputCss = {
  input: {
    display: "none",
  },
  upload: {
    marginRight: 20,
  },
};

const containerCssStyles = makeStyles(containerCss);
const pickerCssStyles = makeStyles(pickerCss);
const inputCssStyles = makeStyles(inputCss);

const BannersList = (props) => {
  const pickerStyles = pickerCssStyles();
  const containerStyles = containerCssStyles();
  const inputStyles = inputCssStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [banners, setBanners] = useState([]);
  const [displayColorPicker, setDisplayColorPicker] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sanday: false,
  });

  const [imageDesktop, setImageDesktop] = useState({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sanday: null,
  });

  const [imageMobile, setImageMobile] = useState({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sanday: null,
  });

  const [bannerLoading, setBannerLoading] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sanday: false,
  });

  const [loading, setLoading] = useState(false);

  const [color, setColor] = useState({
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sanday: "",
  });

  const getList = () => {
    API.get("banners")
      .then(function(res) {
        setBanners(res.data);
        let colors = {};
        res.data.map((c) => {
          colors[c.day] = c.color;
        });
        setColor(colors);
        setLoading(false);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (ability.can("list", "banners")) {
      getList();
    }
  }, []);

  const onChangeColor = (day, e) => {
    setColor({
      ...color,
      [day]: e.hex,
    });
  };

  const handleClick = (day) => {
    setDisplayColorPicker({
      ...displayColorPicker,
      [day]: true,
    });
  };
  const handleClose = (day) => {
    setDisplayColorPicker({
      ...displayColorPicker,
      [day]: false,
    });
  };

  const handleFileChange = (event, type) => {
    if (type === "desktop")
      setImageDesktop({
        ...imageDesktop,
        [event.target.name]: event.target.files[0],
      });
    else
      setImageMobile({
        ...imageMobile,
        [event.target.name]: event.target.files[0],
      });
  };

  const submitBanner = async (event, day, id) => {
    event.preventDefault();
    setBannerLoading({
      ...bannerLoading,
      [day]: true,
    });

    const formData = new FormData();

    formData.append("day", day);
    formData.append("color", color[day]);
    formData.append("image_desktop", imageDesktop[day]);
    formData.append("image_mobile", imageMobile[day]);

    const options = {
      method: "POST",
      data: formData,
      url: "banners/" + id,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const content = validations.successfullyUpdated;

    API(options)
      .then(function(res) {
        getList();
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content,
          },
        });
        setImageDesktop({
          ...imageDesktop,
          [day]: null,
        });
        setImageMobile({
          ...imageMobile,
          [day]: null,
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setBannerLoading({
          ...bannerLoading,
          [day]: false,
        });
      });
  };

  return (
    <>
      {ability.can("list", "banners") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <div className={containerStyles.root}>
              {banners.map((banner) => (
                <div key={banner.day} className={containerStyles.item}>
                  <div className={containerStyles.day}>{banner.title}</div>
                  <div className={containerStyles.image}>
                    <img
                      className={containerStyles.imageDesktop}
                      src={`${process.env.REACT_APP_API_PUBLIC_URL}images/banners/${banner.image_desktop}`}
                      width="79%"
                    />

                    <img
                      className={containerStyles.imageMobile}
                      src={`${process.env.REACT_APP_API_PUBLIC_URL}images/banners/${banner.image_mobile}`}
                      width="20%"
                    />
                    <div className={containerStyles.title}>
                      <span className={containerStyles.titleDesktop}>
                        {imageDesktop[banner.day]?.name || banner.image_desktop}{" "}
                      </span>
                      <span className={containerStyles.titleMobile}>
                        {imageMobile[banner.day]?.name || banner.image_mobile}
                      </span>
                    </div>
                  </div>
                  <div className={containerStyles.actions}>
                    <div
                      className={pickerStyles.swatch}
                      onClick={() => handleClick(banner.day)}
                    >
                      <div
                        className={pickerStyles.color}
                        style={{ background: color[banner.day] }}
                      />
                    </div>
                    <input
                      key={banner.day}
                      accept="image/*"
                      className={inputStyles.input}
                      id={`contained-button-desktop-${banner.day}`}
                      type="file"
                      name={banner.day}
                      onChange={(e) => handleFileChange(e, "desktop")}
                    />
                    <label
                      htmlFor={`contained-button-desktop-${banner.day}`}
                      className={inputStyles.upload}
                    >
                      <Button
                        variant="contained"
                        color={
                          imageDesktop[banner.day]?.name
                            ? "primary"
                            : "secondary"
                        }
                        component="span"
                      >
                        Banniere Desktop
                      </Button>
                    </label>

                    <input
                      key={banner.day}
                      accept="image/*"
                      className={inputStyles.input}
                      id={`contained-button-mobile-${banner.day}`}
                      type="file"
                      name={banner.day}
                      onChange={(e) => handleFileChange(e, "mobile")}
                    />
                    <label
                      htmlFor={`contained-button-mobile-${banner.day}`}
                      className={inputStyles.upload}
                    >
                      <Button
                        variant="contained"
                        color={
                          imageMobile[banner.day]?.name
                            ? "primary"
                            : "secondary"
                        }
                        component="span"
                      >
                        Banniere mobile
                      </Button>
                    </label>

                    <AsyncButton
                      onClick={(e) => submitBanner(e, banner.day, banner.id)}
                      loading={bannerLoading[banner.day]}
                      title={"Sauvegarder"}
                      color="primary"
                      disabled={
                        !imageDesktop[banner.day]?.name ||
                        !imageMobile[banner.day]?.name
                      }
                    ></AsyncButton>

                    {displayColorPicker[banner.day] ? (
                      <div className={pickerStyles.popover}>
                        <div
                          className={pickerStyles.cover}
                          onClick={() => handleClose(banner.day)}
                        />
                        <SketchPicker
                          color={color[banner.day]}
                          onChange={(e) => onChangeColor(banner.day, e)}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default BannersList;
