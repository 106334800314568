import React, { useEffect, useReducer } from "react";
import RightDrawer from "modules/common/RightDrawer";
import RightDrawerFirstChild from "modules/common/RightDrawerFirstChild";
import RightDrawerSecondChild from "modules/common/RightDrawerSecondChild";

import GlobalDialog from "modules/common/GlobalDialog";
import Snackbar from "modules/common/Snackbar";

import { GlobalContext } from "context/GlobalContext";
import globalReducer from "context/reducer";
import globalState from "context/state";

import { AbilityContext } from "context/AbilityContext";
import ability from "config/ability";
import Cookie from "js-cookie";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const [state, dispatch] = useReducer(globalReducer, globalState);

  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [logo, setLogo] = React.useState("");
  // const [hasImage, setHasImage] = React.useState(true);
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Set up language
    const lang = Cookie.get("lang") ?? "fr";
    dispatch({ type: "SET_LANG", payload: lang });

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const tokenExist = Cookie.get("tgo") ? true : false;

  return (
    <AbilityContext.Provider value={ability}>
      <GlobalContext.Provider
        value={{ state, dispatch }}
        className={classes.wrapper}
      >
        {tokenExist ? (
          <>
            <Sidebar
              routes={routes}
              image={"assets/img/sidebar-2.jpg"}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={"blue"}
              bgColor={"black"}
              lang={state.lang}
              miniActive={miniActive}
              {...rest}
            />
            <RightDrawer
              open={state.drawer}
              width={state.drawerWidth}
              title={state.drawerTitle}
            >
              {state.drawerContent}
            </RightDrawer>

            <RightDrawerFirstChild
              open={state.drawerFirstChild}
              width={state.drawerFirstChildWidth}
              title={state.drawerFirstChildTitle}
            >
              {state.drawerFirstChildContent}
            </RightDrawerFirstChild>

            <RightDrawerSecondChild
              open={state.drawerSecondChild}
              width={state.drawerSecondChildWidth}
              title={state.drawerSecondChildTitle}
            >
              {state.drawerSecondChildContent}
            </RightDrawerSecondChild>

            <GlobalDialog
              open={state.dialog}
              width={state.dialogWidth}
              title={state.dialogTitle}
            >
              {state.dialogContent}
            </GlobalDialog>
            <Snackbar />
            <div className={mainPanelClasses} ref={mainPanel}>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText={getActiveRoute(routes)}
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      {getRoutes(routes)}
                      <Redirect from="/admin" to="/admin/dashboard" />
                    </Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/admin" to="/admin/dashboard" />
                  </Switch>
                </div>
              )}
              {getRoute() ? <Footer fluid /> : null}
            </div>
          </>
        ) : (
          <Redirect from="/admin" to="/auth/login" />
        )}
      </GlobalContext.Provider>
    </AbilityContext.Provider>
  );
}
