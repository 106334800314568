import React, { useState, useEffect, useContext } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Close from "@material-ui/icons/Close";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  fullwidth: {
    width: "95%"
  },
  normalWidth: {
    width: "900"
  },
  close: {
    float: "right",
    cursor: "pointer",
    "&:hover": {
      color: "#c62828"
    }
  }
}));

const RightDrawer = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);

  const closeDrawer = () => {
    dispatch({ type: "CLOSE_DRAWER" });
  };

  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={state.drawer}
      onClose={closeDrawer}
      classes={
        state.drawerWidth === "full"
          ? {
              paper: classes.fullwidth
            }
          : {
              paper: classes.normalWidth
            }
      }
    >
      <Container component="main">
        <Box mt={3} textAlign="left" component="h3" variant="h5">
          {state.drawerTitle}
          <Close
            fontSize="large"
            className={classes.close}
            onClick={closeDrawer}
          />
        </Box>

        {props.children}
        {props.ctxx}
      </Container>
    </Drawer>
  );
};

export default RightDrawer;
