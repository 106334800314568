import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FileCopy from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EventIcon from "@material-ui/icons/Event";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswerOutlined";
import StarBorderIcon from "@material-ui/icons/StarBorderOutlined";
import LayersIcon from "@material-ui/icons/Layers";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import CourseSessions from "forms/CourseSessions";
import CoursePrograms from "forms/CoursePrograms";
import CourseReviews from "forms/CourseReviews";
import CourseFaq from "forms/CourseFaq";
import Form from "forms/Course";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const CoursesTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [copiedSalesforceId, setCopiedSalesforceId] = useState("nothing");

  const [loading, setLoading] = useState(false);

  const tableColumns = [
    {
      title: "",
      field: "img_name",
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
      render: (data) => <img width="60px" src={data.img_url} />,
    },
    {
      title: "ID",
      field: "id",
      cellStyle: {
        width: 10,
        maxWidth: 10,
      },
      headerStyle: {
        width: 10,
        maxWidth: 10,
      },
    },
    {
      title: "Salesforce",
      field: "salesforce_id",
      render: (data) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: 8 }}>{data.salesforce_id}</span>
          {copiedSalesforceId == data.salesforce_id ? (
            <Tooltip title="Copié">
              <IconButton size="small" component="span">
                <CheckIcon size="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Copier">
              <IconButton
                size="small"
                onClick={() => copyToClipBoard(data.salesforce_id)}
                component="span"
              >
                <FileCopy size="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
      cellStyle: {
        width: 250,
        maxWidth: 250,
      },
      headerStyle: {
        width: 250,
        maxWidth: 250,
      },
    },
    {
      title: "Sessions",
      field: "sessions",
      render: (data) => (
        <IconButton
          onClick={() => openFullscreenDialog("sessions", data)}
          component="span"
        >
          <EventIcon />
        </IconButton>
      ),
      sorting: false,
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
    {
      title: "FAQs",
      sorting: false,
      render: (data) => (
        <IconButton
          onClick={() => openFullscreenDialog("faq", data)}
          component="span"
        >
          <QuestionAnswerIcon />
        </IconButton>
      ),
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
    {
      title: "Reviews",
      sorting: false,
      render: (data) => (
        <IconButton
          onClick={() => openFullscreenDialog("reviews", data)}
          component="span"
        >
          <StarBorderIcon />
        </IconButton>
      ),
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
    {
      title: "Titre",
      field: "title",
      cellStyle: {
        width: 350,
        maxWidth: 350,
      },
      headerStyle: {
        width: 350,
        maxWidth: 350,
      },
    },
    // {
    //   title: "Prérequis",
    //   field: "prerequisites",
    //   render: (data) =>
    //     data.prerequisites ? (
    //       <CheckIcon style={{ color: "green" }} />
    //     ) : (
    //       <ClearIcon style={{ color: "red" }} />
    //     ),
    //   cellStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    //   headerStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    // },
    // {
    //   title: "Evaluation",
    //   field: "evaluation",
    //   cellStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    //   headerStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    // },
    // {
    //   title: "Certif",
    //   field: "certification",
    //   render: (data) =>
    //     data.certification ? (
    //       <CheckIcon style={{ color: "green" }} />
    //     ) : (
    //       <ClearIcon style={{ color: "red" }} />
    //     ),
    //   cellStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    //   headerStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    // },
    // {
    //   title: "Visites",
    //   field: "visits",
    //   cellStyle: {
    //     width: 100,
    //     maxWidth: 100,
    //   },
    //   headerStyle: {
    //     width: 100,
    //     maxWidth: 100,
    //   },
    // },
    {
      title: "Active",
      field: "active",
      initialEditValue: true,
      render: (data) =>
        data.active ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <ClearIcon style={{ color: "red" }} />
        ),
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
  ];
  const getList = () => {
    API.get("courses")
      .then(function(res) {
        setLoading(false);
        const pageLoad = Math.random()
          .toString(36)
          .substring(7);
        let list = res.data.list.map((l) => ({
          ...l,
          img_url:
            process.env.REACT_APP_API_PUBLIC_URL +
            "images/formations/thumbs/" +
            l.img_name +
            "?" +
            pageLoad,
        }));
        setCourses(list);
        setCategories(res.data.categories);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setColumns(tableColumns);

    if (ability.can("list", "courses")) {
      getList();
    }
  }, []);

  useEffect(() => {
    setColumns(tableColumns);
  }, [copiedSalesforceId]);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopiedSalesforceId(copyMe);
    } catch (err) {
      alert("Erreur de copie!");
    }
  };

  const addItem = (event) => {
    if (ability.can("create", "courses")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouvelle formation",
          content: (
            <Form
              action={"post"}
              categories={categories}
              onChange={createCourse}
            />
          ),
        },
      });
    }
  };

  const openFullscreenDialog = (section, course) => {
    if (section === "sessions" && ability.can("list", "courseSessions"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les sessions - " + course.title,
          width: "full",
          content: <CourseSessions course={course} />,
        },
      });
    else if (section === "program" && ability.can("list", "courseLessons"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Le programme - " + course.title,
          width: "full",
          content: <CoursePrograms course={course} />,
        },
      });
    else if (section === "reviews" && ability.can("list", "courseReviews"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les avis - " + course.title,
          width: "full",
          content: <CourseReviews course={course} />,
        },
      });
    else if (section === "faq" && ability.can("list", "courseFaq"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les faqs - " + course.title,
          width: "full",
          content: <CourseFaq course={course} />,
        },
      });
  };

  const createCourse = () => {
    if (ability.can("create", "courses")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const updateCourse = () => {
    if (ability.can("update", "courses")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "courses")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          width: "full",
          title: rowData.title,
          content: (
            <Form
              action={"put"}
              course={rowData}
              categories={categories}
              onChange={updateCourse}
            />
          ),
        },
      });
    }
  };

  return (
    <>
      {ability.can("list", "courses") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              options={{ pageSize: 3, pageSizeOptions: [1, 3, 5, 10, 20] }}
              columns={columns}
              data={courses}
              defaultSorted={[
                {
                  id: "title",
                  desc: true,
                },
              ]}
              editable={
                ability.can("delete", "courses") && {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      console.log(oldData.id);
                      API.delete("courses/" + oldData.id)
                        .then(() => {
                          setCourses(
                            courses.filter((row) => row.id !== oldData.id)
                          );
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    }),
                }
              }
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default CoursesTable;
