import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import { GlobalContext } from "context/GlobalContext";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import validations from "assets/js/validations";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

const LoginPage = props => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const { state, dispatch } = useContext(GlobalContext);

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: ""
  });
  const handleChange = event => {
    setLogin({
      ...login,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    API.post("login", { email: login.email, password: login.password })
      .then(function(res) {
        dispatch({
          type: "LOGIN",
          payload: {
            token: res.data.token
          }
        });
        window.location.href = "/admin";
      })
      .catch(e => {
        if (e.response.status === 401) {
          console.log(e.response.status);
          dispatch({
            type: "OPEN_SNACKBAR",
            payload: {
              type: "error",
              content: validations.loginError
            }
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Bcloud Administration</h4>
              </CardHeader>
              <CardBody>
                <ValidatorForm onSubmit={handleSubmit}>
                  <Grid spacing={2}>
                    <Grid item xs={12} mb={3}>
                      <TextValidator
                        label="Email"
                        type="email"
                        fullWidth
                        className={classes.textField}
                        onChange={handleChange}
                        name="email"
                        variant="outlined"
                        margin="dense"
                        value={login.email}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          validations.required,
                          validations.isEmail
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.pt}>
                      <TextValidator
                        className={classes.textField}
                        label="Mot de passe"
                        type="password"
                        fullWidth
                        onChange={handleChange}
                        name="password"
                        variant="outlined"
                        margin="dense"
                        value={login.password}
                        validators={["required"]}
                        errorMessages={[validations.required]}
                      />
                    </Grid>

                    <Grid item xs={12} className={classes.pt}>
                      <AsyncButton
                        type="submit"
                        loading={loading}
                        title={"Se connecter"}
                        color="primary"
                      ></AsyncButton>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </CardBody>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default withRouter(LoginPage);
