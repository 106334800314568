import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    width: "100%",
  },
}));

const PermissionsForm = (props) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(props.open);
    let role = props.role;
    setDataLoading(true);
    API.get("roles/" + role.id)
      .then(function(res) {
        setDataLoading(false);
        setPermissions(res.data.permissions);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setDataLoading(false);
      });
  }, []);

  const handleCheckChange = (event) => {
    let p = JSON.parse(JSON.stringify(permissions));
    let name = event.target.name;

    let parts = name.split(",");
    p[parts[1]]["permissions"][parts[2]]["selected"] = event.target.checked;
    setPermissions(p);
  };

  const selectAll = (bool) => {
    let perms = JSON.parse(JSON.stringify(permissions));

    for (let [gi, g] of Object.entries(perms)) {
      for (let [pi, p] of Object.entries(g.permissions)) {
        perms[gi]["permissions"][pi]["selected"] = bool;
      }
    }
    setPermissions(perms);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let updatedPermissions = { permissions: [] };

    for (const g of permissions) {
      for (const p of g.permissions) {
        if (p.selected) updatedPermissions.permissions.push(p.id);
      }
    }
    console.log(updatedPermissions);

    API.put("roles/" + props.role.id + "/permissions", updatedPermissions)
      .then(function(res) {
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content: validations.successfullyUpdated,
          },
        });
        props.onChange(res.data);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const groupName = (group) => {
    if (group === "roles") return "Rôles et permissions";
    else if (group === "courseCategories")
      return "Les catégories des formations";
    else if (group === "courses") return "Les formations";
    else if (group === "courseFaq") return "Les FAQ des formations";
    else if (group === "courseLessons") return "Les cours des formations";
    else if (group === "courseSessions") return "Les sessions des formations";
    else if (group === "vouchers") return "Les coupons";
    else if (group === "currencies") return "Les devises";
    else if (group === "certifs") return "Les certifications";
    else if (group === "certifFaq") return "Les FAQ des certifications";
    else if (group === "courseReviews") return "Les avis des formations";
    else if (group === "sessionsDiscounts")
      return "Les promotions des sessions";
    else if (group === "orders") return "Les commandes";
    else if (group === "managers") return "Les agents";
    else if (group === "callbacks") return "Les demandes d'appel";
    else if (group === "users") return "Les clients";
    else if (group === "simulators") return "Les simulateurs";
    else if (group === "simulatorExercices")
      return "Les exercies d'un simulateur";
    else if (group === "simulatorQuestions")
      return "Les questions d'un simulateur";
    else if (group === "simulatorAnswers")
      return "Les réponses d'un simulateur";
    else if (group === "simulatorReviews") return "Les avis d'un simulateur";
    else if (group === "simulatorUsers")
      return "Les utilisateurs d'un simulateur";
    else if (group === "trainers") return "Les formateurs Bcloud";
    else if (group === "businessQuotes") return "Les demande de devis";
    else return group;
  };
  return (
    <>
      {dataLoading ? (
        <CircularProgress
          thickness={2}
          style={{
            width: "100px",
            height: "100px",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
          }}
        />
      ) : (
        <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
          <Grid container ma spacing={2}>
            {permissions.map((p, i) => {
              return (
                <Grid key={i} item xs={12} lg={3} md={4} sm={6}>
                  <Typography variant="body1">{groupName(p.group)}</Typography>

                  {p.permissions.map((permission, index) => {
                    return (
                      <div key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={
                                "permissions," + i + "," + index + ",selected"
                              }
                              checked={
                                permissions[i]["permissions"][index]["selected"]
                              }
                              value={
                                permissions[i]["permissions"][index]["selected"]
                              }
                              onChange={handleCheckChange}
                              color="primary"
                            />
                          }
                          label={permission.name}
                        />
                      </div>
                    );
                  })}
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <AsyncButton
                type="submit"
                loading={loading}
                title={"Sauvegarder"}
                color="primary"
              ></AsyncButton>
              <Box component="span" ml={1}>
                <Button
                  onClick={() => selectAll(true)}
                  variant="contained"
                  color="primary"
                >
                  {"Tout selectionner"}
                </Button>
              </Box>
              <Box component="span" ml={1}>
                <Button
                  onClick={() => selectAll(false)}
                  variant="contained"
                  color="primary"
                >
                  {"Tout déselectionner"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </ValidatorForm>
      )}
    </>
  );
};

export default PermissionsForm;
