import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "450px"
  },
  textField: {
    width: "100%"
  }
}));

const CurrencyForm = props => {
  const classes = useStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [currency, setCurrency] = useState({
    code: "",
    symbol: "",
    rate: "",
    format: "",
    default: false,
    active: true
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setCurrency(props.currency);
  }, []);

  const handleChange = event => {
    setCurrency({
      ...currency,
      [event.target.name]: event.target.value
    });
  };

  const handleCheckChange = event => {
    setCurrency({
      ...currency,
      [event.target.name]: event.target.checked
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    let id = props.action === "put" ? "/" + props.currency.code : "";
    const options = {
      method: props.action,
      data: currency,
      url: "currencies" + id
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: { type: "success", content: content }
        });
      })
      .catch(e => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextValidator
            label="Code"
            className={classes.textField}
            onChange={handleChange}
            name="code"
            value={currency.code}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Symbol"
            onChange={handleChange}
            name="symbol"
            value={currency.symbol}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Taux"
            onChange={handleChange}
            name="rate"
            value={currency.rate}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Format d'affichage"
            onChange={handleChange}
            name="format"
            helperText={"format d'affichage, Ex: {amount} DH >>> 4000 DH"}
            value={currency.format}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="active"
                checked={currency.active}
                value={currency.active}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Active."
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="default"
                checked={currency.default}
                value={currency.default}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Default"
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default CurrencyForm;
