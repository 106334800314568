import axios from "axios";
import Cookie from "js-cookie";

export default axios.create({
  //withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    Authorization: "Bearer " + Cookie.get("tgo"),
    "X-LANG": Cookie.get("lang") ?? "fr",
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});
