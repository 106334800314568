import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";

import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "450px"
  },
  textField: {
    width: "100%"
  }
}));

const ManagerForm = props => {
  const classes = useStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [manager, setManager] = useState({
    fname: "",
    lname: "",
    email: "",
    roles: [],
    password: "",
    active: true
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setManager(props.manager);
    console.log(props.roles)
  }, []);

  const handleChange = event => {
    setManager({
      ...manager,
      [event.target.name]: event.target.value
    });
  };

  const handleCheckChange = event => {
    setManager({
      ...manager,
      [event.target.name]: event.target.checked
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    let id = props.action === "put" ? "/" + manager.id : "";
    const options = {
      method: props.action,
      data: manager,
      url: "managers" + id
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: { type: "success", content: content }
        });
      })
      .catch(e => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextValidator
              label="Prénom"
              className={classes.textField}
              onChange={handleChange}
              name="fname"
              value={manager.fname}
              validators={["required"]}
              variant="outlined"
              margin="dense"
              errorMessages={[validations.required]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextValidator
              className={classes.textField}
              label="Nom"
              onChange={handleChange}
              name="lname"
              value={manager.lname}
              validators={["required"]}
              variant="outlined"
              margin="dense"
              errorMessages={[validations.required]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              className={classes.textField}
              label="Email"
              onChange={handleChange}
              name="email"
              value={manager.email}
              validators={["required"]}
              variant="outlined"
              margin="dense"
              errorMessages={[validations.required]}
            />
          </Grid>

          {props.action === "put" && manager.roles.includes(1) ? null : (
            <Grid item xs={12}>
              <SelectValidator
                className={classes.textField}
                multiple
                fullWidth
                label="Rôles"
                name="roles"
                value={manager.roles}
                onChange={handleChange}
                validators={["required"]}
                variant="outlined"
                margin="dense"
                SelectProps={{
                  multiple: true
                }}
                errorMessages={[validations.required]}
              >
                {props.roles.map(r => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextValidator
              className={classes.textField}
              label="Mot de passe"
              type="password"
              onChange={handleChange}
              name="password"
              value={manager.password}
              validators={props.action === "post" ? ["required"] : []}
              errorMessages={[validations.required]}
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="active"
                  checked={manager.active}
                  value={manager.active}
                  onChange={handleCheckChange}
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12}>
            <AsyncButton
              type="submit"
              loading={loading}
              title={"Sauvegarder"}
              color="primary"
            ></AsyncButton>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  );
};

export default ManagerForm;
