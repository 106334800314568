import React, { useEffect, forwardRef, useState, useContext } from "react";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CircularProgress from "@material-ui/core/CircularProgress";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Form from "modules/common/Region";
import API from "api";

const SitesTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);

  const [loading, setLoading] = useState(false);

  const getList = () => {
    API.get("regions")
      .then(function(res) {
        setLoading(false);
        setRegions(res.data);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });

    API.get("countries")
      .then(function(res) {
        setLoading(false);
        setCountries(res.data);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setColumns([
      {
        title: "Name",
        field: "name",
        cellStyle: {
          width: 160,
          maxWidth: 160,
        },
        headerStyle: {
          width: 160,
          maxWidth: 160,
        },
      },
      {
        title: "Countries",
        field: "countries",
        cellStyle: {
          width: 600,
          maxWidth: 600,
        },
        headerStyle: {
          width: 600,
          maxWidth: 600,
        },
        render: (data) =>
          data.countries.map((country, index) => (
            <span key={index}>
              {country.name}
              {index < data.countries.length - 1 ? " - " : "."}
            </span>
          )),
      },
    ]);

    if (ability.can("list", "regions")) {
      getList();
    }
  }, []);

  const addItem = (event) => {
    if (ability.can("create", "regions")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouvelle région",
          content: (
            <Form
              action={"post"}
              onChange={createRegion}
              countries={countries}
            />
          ),
        },
      });
    }
  };

  const createRegion = () => {
    if (ability.can("create", "regions")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const updateSite = () => {
    if (ability.can("update", "regions")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "regions")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: rowData.name,
          content: (
            <Form
              action={"put"}
              region={{
                ...rowData,
                countries: rowData.countries.map((c) => c.id),
              }}
              countries={countries}
              onChange={updateSite}
            />
          ),
        },
      });
    }
  };

  return (
    <>
      {ability.can("list", "regions") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              options={{ pageSize: 3, pageSizeOptions: [1, 3, 5, 10, 20] }}
              columns={columns}
              data={regions}
              defaultSorted={[
                {
                  id: "title",
                  desc: true,
                },
              ]}
              editable={
                ability.can("delete", "regions") && {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      console.log(oldData.id);
                      API.delete("regions/" + oldData.id)
                        .then(() => {
                          setRegions(
                            regions.filter((row) => row.id !== oldData.id)
                          );
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    }),
                }
              }
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default SitesTable;
