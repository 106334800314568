import React, { useState, useEffect, useContext } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Close from "@material-ui/icons/Close";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  close: {
    float: "right",
    cursor: "pointer",
    "&:hover": {
      color: "#c62828"
    }
  }
});

const GlobalDialog = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);

  const closeDialog = () => {
    dispatch({ type: "CLOSE_DIALOG" });
  };

  const { classes } = props;

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      fullScreen={state.dialogFullScreen}
      open={state.dialog}
      TransitionComponent={Transition}
      onClose={closeDialog}
    >
      <Container component="main">
        <Box mt={3} textAlign="left" component="h3" variant="h5">
          {state.dialogTitle}
          <Close
            fontSize="large"
            className={classes.close}
            onClick={closeDialog}
          />
        </Box>
        {props.children}
        {props.ctxx}
      </Container>
    </Dialog>
  );
};

export default withStyles(styles)(GlobalDialog);
