import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Form from "forms/CertificationCategory";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const CertificationCategoriesTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);

    setColumns([
      {
        title: "Titre",
        field: "title",
        cellStyle: {
          width: 200,
          maxWidth: 200,
        },
        headerStyle: {
          width: 200,
          maxWidth: 200,
        },
      },
      {
        title: "Lien",
        field: "permalink",
        cellStyle: {
          width: 150,
          maxWidth: 150,
        },
        headerStyle: {
          width: 150,
          maxWidth: 150,
        },
      },
      {
        title: "Déscription",
        field: "description",
        cellStyle: {
          width: 200,
          maxWidth: 200,
        },
        headerStyle: {
          width: 200,
          maxWidth: 200,
        },
      },
      {
        title: "Active",
        field: "active",
        initialEditValue: true,
        render: (data) =>
          data.active ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
    ]);
    if (ability.can("list", "certificationCategories")) {
      API.get("certifs/categories")
        .then(function(res) {
          setLoading(false);
          setCategories(res.data);
        })
        .catch((e) => {
          dispatch({ type: "CATCH_ERROR", payload: e });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const addItem = (event) => {
    if (ability.can("create", "certificationCategories")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouvelle catégorie de certification",
          content: <Form action={"post"} onChange={createCategory} />,
        },
      });
    }
  };

  const createCategory = (currency) => {
    if (ability.can("create", "certificationCategories")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      let newCategories = JSON.parse(JSON.stringify(categories));
      newCategories.push(currency);
      setCategories(newCategories);
    }
  };

  const updateCategory = (currency) => {
    if (ability.can("update", "certificationCategories")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      let currencyIndex = categories.findIndex((c) => c.id == currency.id);
      let newCategories = JSON.parse(JSON.stringify(categories));
      newCategories[currencyIndex] = currency;

      setCategories(newCategories);
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "certificationCategories")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Modification de la catégorie " + rowData.title,
          content: (
            <Form action={"put"} category={rowData} onChange={updateCategory} />
          ),
        },
      });
    }
  };
  return (
    <>
      {ability.can("list", "certificationCategories") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              options={{ pageSize: 3, pageSizeOptions: [1, 3, 5, 10, 20] }}
              columns={columns}
              data={categories}
              defaultSorted={[
                {
                  id: "title",
                  desc: true,
                },
              ]}
              editable={
                ability.can("delete", "certificationCategories") && {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      API.delete("certifs/categories/" + oldData.id)
                        .then(() => {
                          setCategories(
                            categories.filter((c) => c.id !== oldData.id)
                          );
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    }),
                }
              }
              actions={[
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default CertificationCategoriesTable;
