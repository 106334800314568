import React, { useEffect, forwardRef, useState, useContext } from "react";
import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import validations from "assets/js/validations";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import SimulatorQuestions from "modules/simulator/SimulatorQuestions";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";

const SimulatorUsers = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setColumns([
        {
            title: "Active",
            field: "active",
            initialEditValue: true,
            render: data =>
                data.active ? (
                    <CheckIcon style={{ color: "green" }} />
                ) : (
                    <ClearIcon style={{ color: "red" }} />
                ),
            editComponent: props => (
                    <Switch
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                      value={props.value}
                      color="primary"
                    />
            ),
            cellStyle: {
            width: 60,
            maxWidth: 60
            },
            headerStyle: {
            width: 60,
            maxWidth: 60
            }
        },
        {
            title: "ID",
            field: "user_id",
            editable: "onAdd",
            cellStyle: {
                width: 120,
                maxWidth: 120
            },
            headerStyle: {
                width: 120,
                maxWidth: 120
            }
        },
        {
            title: "Client",
            field: "username",
            editable: "never",
            cellStyle: {
            width: 400,
            maxWidth: 400
            },
            headerStyle: {
            width: 400,
            maxWidth: 400
            }
        },
        {
            title: "Temps restant",
            field: "time_remaining",
            editable: "never",
            render: data =>
                data && data.time_remaining ? data.time_remaining + " minutes" : "-",
            cellStyle: {
            width: 400,
            maxWidth: 400
            },
            headerStyle: {
            width: 400,
            maxWidth: 400
            }
        },
        {
            title: "Temps passé",
            field: "time_passed",
            editable: "never",
            render: data =>
                data && data.time_passed ? data.time_passed + " minutes" : "-",
            cellStyle: {
            width: 120,
            maxWidth: 120
            },
            headerStyle: {
            width: 120,
            maxWidth: 120
            }
        },
        {
            title: "Affecté le",
            field: "created_at",
            editable: "never",
            cellStyle: {
            width: 800,
            maxWidth: 800
            },
            headerStyle: {
            width: 800,
            maxWidth: 800
            }
        }
    ]);

    API.get("simulators/" + props.simulator.id + "/users")
      .then(function(res) {
        setLoading(false);
        setData(res.data);
      })
      .catch(e => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  
  return (
    <>
      {ability.can("list", "SimulatorUsers") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto"
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              columns={columns}
              data={data}
              defaultSorted={[
                {
                  id: "rank",
                  asc: true
                }
              ]}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    if (
                      newData.user_id !== ""
                    ) {
                      API.post("simulators/" + props.simulator.id + "/users", newData)
                        .then(res => {
                          const editedData = JSON.parse(JSON.stringify(data));
                          editedData.push(res.data);
                          setData(editedData);
                          resolve();
                        })
                        .catch(e => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          resolve();
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    } else {
                      reject();
                      alert(validations.allFieldsRequired);
                    }
                  }),
                  onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    if (
                      newData.title !== "" &&
                      newData.description !== "" &&
                      newData.rank !== "" &&
                      newData.active !== ""
                    ) {
                      API.put("simulators/" + props.simulator.id + "/users/" + oldData.id, newData)
                        .then(() => {
                          const editedData = JSON.parse(JSON.stringify(data));
                          const index = data.indexOf(oldData);
                          editedData[index] = newData;
                          setData(editedData);

                          resolve();
                        })
                        .catch(e => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    } else {
                      reject();
                      alert(validations.allFieldsRequired);
                      /**
                  dispatch({
                    type: "OPEN_SNACKBAR",
                    payload: {
                      type: "error",
                      content: validations.allFieldsRequired
                    }
                  });
                  */
                    }
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    API.delete("simulators/" + props.simulator.id + "/users/" + oldData.id)
                      .then(() => {
                        setData(data.filter(row => row.id !== oldData.id));
                        resolve();
                      })
                      .catch(e => {
                        dispatch({ type: "CATCH_ERROR", payload: e });
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  })
              }}
              options={{
                exportButton: true
              }}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                ))
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page"
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche"
                },
                header: {
                  actions: "Actions"
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter"
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder"
                  }
                }
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default SimulatorUsers;
