import React, { useEffect, forwardRef, useState, useContext } from "react";
import moment from "moment";
import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Form from "forms/Voucher";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const VouchersTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [vouchers, setVouchers] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setColumns([
      {
        title: "Nom",
        field: "name",
        render: (data) => (
          <>
            <div>
              {data.name} <small>{data.product_name && "(Produit)"}</small>
            </div>
            {data.user && !data.product_name && (
              <>
                {"pour "} {data.user.fname + " " + data.user.lname}
                <br />
              </>
            )}
            {data.product_name && !data.user && (
              <small>{data.product_name}</small>
            )}
            {!data.product_name && !data.user && <small>Coupon global</small>}
            {data.product_name && data.user && (
              <Tooltip title={data.product_name}>
                <small>
                  {"pour "} {data.user.fname + " " + data.user.lname}
                </small>
              </Tooltip>
            )}
          </>
        ),
        cellStyle: {
          width: 200,
          maxWidth: 200,
        },
        headerStyle: {
          width: 200,
          maxWidth: 200,
        },
      },
      {
        title: "Type",
        field: "type",
        render: (data) => {
          if (data.type == "fix") {
            return "Montant fixé";
          } else if (data.type == "percent") {
            return "Pourcentage";
          } else {
            return "Code Parrainage";
          }
        },
        cellStyle: {
          width: 100,
          maxWidth: 100,
        },
        headerStyle: {
          width: 100,
          maxWidth: 100,
        },
      },
      {
        title: "Valeur",
        field: "value",
        cellStyle: {
          width: 60,
          maxWidth: 60,
        },
        headerStyle: {
          width: 60,
          maxWidth: 60,
        },
      },
      {
        title: "1 fois",
        field: "unique_usage",
        render: (data) =>
          data.unique_usage ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 100,
          maxWidth: 100,
        },
        headerStyle: {
          width: 100,
          maxWidth: 100,
        },
      },
      {
        title: "Utilisé",
        field: "used",
        render: (data) =>
          data.used ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
      {
        title: "Éxpire le",
        field: "expire",
        render: (data) => moment.utc(data.expire).format("LLL"),
        cellStyle: {
          width: 130,
          maxWidth: 130,
        },
        headerStyle: {
          width: 130,
          maxWidth: 130,
        },
      },
      {
        title: "Active",
        field: "active",
        initialEditValue: true,
        render: (data) =>
          data.active ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
    ]);

    if (ability.can("list", "vouchers")) {
      API.get("vouchers")
        .then(function(res) {
          setLoading(false);
          setVouchers(res.data);
        })
        .catch((e) => {
          dispatch({ type: "CATCH_ERROR", payload: e });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const addItem = (event) => {
    if (ability.can("create", "vouchers")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouvelle coupon",
          content: <Form action={"post"} onChange={createVoucher} />,
        },
      });
    }
  };

  const createVoucher = (course) => {
    if (ability.can("create", "vouchers")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      let newVouchers = JSON.parse(JSON.stringify(vouchers));
      newVouchers.unshift(course);
      setVouchers(newVouchers);
    }
  };

  const updateVoucher = (course) => {
    if (ability.can("update", "vouchers")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      let courseIndex = vouchers.findIndex((c) => c.id == course.id);
      let newVouchers = JSON.parse(JSON.stringify(vouchers));
      newVouchers[courseIndex] = course;

      setVouchers(newVouchers);
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "vouchers")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: rowData.title,
          content: (
            <Form action={"put"} voucher={rowData} onChange={updateVoucher} />
          ),
        },
      });
    }
  };
  return (
    <>
      {ability.can("list", "vouchers") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              columns={columns}
              data={vouchers}
              defaultSorted={[
                {
                  id: "created_at",
                  desc: true,
                },
              ]}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    console.log(oldData.id);
                    API.delete("vouchers/" + oldData.id)
                      .then(() => {
                        let data = JSON.parse(JSON.stringify(vouchers));
                        data = data.filter((c) => c.id !== oldData.id);
                        setVouchers(data);
                        resolve();
                      })
                      .catch((e) => {
                        dispatch({ type: "CATCH_ERROR", payload: e });
                        reject();
                      });
                  }),
              }}
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default VouchersTable;
