export default {
  // user
  isAuthenticated: false,
  user: null,
  token: null,
  lang: "fr",

  // RightDrawer
  drawer: false,
  maxWidth: null,
  drawerTitle: null,
  drawerWidth: "",
  drawerContent: null,

  // RightDrawerFirstChild
  drawerFirstChild: false,
  maxWidth: null,
  drawerFirstChildTitle: null,
  drawerFirstChildWidth: "",
  drawerFirstChildContent: null,

  // RightDrawerSecondChild
  drawerSecondChild: false,
  maxWidth: null,
  drawerSecondChildTitle: null,
  drawerSecondChildWidth: "",
  drawerSecondChildContent: null,

  // Dialog
  dialog: false,
  dialogTitle: null,
  dialogFullScreen: false,
  dialogWidth: true,
  dialogContent: null,

  // Snackbar
  snackbar: false,
  snackbarType: null,
  snackbarContent: null,
};
