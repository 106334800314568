export default [
  { value: "AF", text: "Afghanistan" },
  { value: "ZA", text: "Afrique du Sud" },
  { value: "AL", text: "Albanie" },
  { value: "DZ", text: "Alg\u00e9rie" },
  { value: "DE", text: "Allemagne" },
  { value: "AD", text: "Andorre" },
  { value: "AO", text: "Angola" },
  { value: "AI", text: "Anguilla" },
  { value: "AQ", text: "Antarctique" },
  { value: "AG", text: "Antigua-et-Barbuda" },
  { value: "AN", text: "Antilles n\u00e9erlandaises" },
  { value: "SA", text: "Arabie saoudite" },
  { value: "AR", text: "Argentine" },
  { value: "AM", text: "Arm\u00e9nie" },
  { value: "AW", text: "Aruba" },
  { value: "AU", text: "Australie" },
  { value: "AT", text: "Autriche" },
  { value: "AZ", text: "Azerba\u00efdjan" },
  { value: "BS", text: "Bahamas" },
  { value: "BH", text: "Bahre\u00efn" },
  { value: "BD", text: "Bangladesh" },
  { value: "BB", text: "Barbade" },
  { value: "BY", text: "B\u00e9larus" },
  { value: "BE", text: "Belgique" },
  { value: "BZ", text: "Belize" },
  { value: "BJ", text: "B\u00e9nin" },
  { value: "BM", text: "Bermudes" },
  { value: "BT", text: "Bhoutan" },
  { value: "BO", text: "Bolivie" },
  { value: "BA", text: "Bosnie-Herz\u00e9govine" },
  { value: "BW", text: "Botswana" },
  { value: "BR", text: "Br\u00e9sil" },
  { value: "BN", text: "Brun\u00e9i Darussalam" },
  { value: "BG", text: "Bulgarie" },
  { value: "BF", text: "Burkina Faso" },
  { value: "BI", text: "Burundi" },
  { value: "KH", text: "Cambodge" },
  { value: "CM", text: "Cameroun" },
  { value: "CA", text: "Canada" },
  { value: "CV", text: "Cap-Vert" },
  { value: "EA", text: "Ceuta et Melilla" },
  { value: "CL", text: "Chili" },
  { value: "CN", text: "Chine" },
  { value: "CY", text: "Chypre" },
  { value: "CO", text: "Colombie" },
  { value: "KM", text: "Comores" },
  { value: "CG", text: "Congo-Brazzaville" },
  { value: "KP", text: "Cor\u00e9e du Nord" },
  { value: "KR", text: "Cor\u00e9e du Sud" },
  { value: "CR", text: "Costa Rica" },
  { value: "CI", text: "C\u00f4te d\u2019Ivoire" },
  { value: "HR", text: "Croatie" },
  { value: "CU", text: "Cuba" },
  { value: "DK", text: "Danemark" },
  { value: "DG", text: "Diego Garcia" },
  { value: "DJ", text: "Djibouti" },
  { value: "DM", text: "Dominique" },
  { value: "EG", text: "\u00c9gypte" },
  { value: "SV", text: "El Salvador" },
  { value: "AE", text: "\u00c9mirats arabes unis" },
  { value: "EC", text: "\u00c9quateur" },
  { value: "ER", text: "\u00c9rythr\u00e9e" },
  { value: "ES", text: "Espagne" },
  { value: "EE", text: "Estonie" },
  { value: "VA", text: "\u00c9tat de la Cit\u00e9 du Vatican" },
  { value: "FM", text: "\u00c9tats f\u00e9d\u00e9r\u00e9s de Micron\u00e9sie" },
  { value: "US", text: "\u00c9tats-Unis" },
  { value: "ET", text: "\u00c9thiopie" },
  { value: "FJ", text: "Fidji" },
  { value: "FI", text: "Finlande" },
  { value: "FR", text: "France" },
  { value: "GA", text: "Gabon" },
  { value: "GM", text: "Gambie" },
  { value: "GE", text: "G\u00e9orgie" },
  { value: "GS", text: "G\u00e9orgie du Sud et les \u00eeles Sandwich du Sud" },
  { value: "GH", text: "Ghana" },
  { value: "GI", text: "Gibraltar" },
  { value: "GR", text: "Gr\u00e8ce" },
  { value: "GD", text: "Grenade" },
  { value: "GL", text: "Groenland" },
  { value: "GP", text: "Guadeloupe" },
  { value: "GU", text: "Guam" },
  { value: "GT", text: "Guatemala" },
  { value: "GG", text: "Guernesey" },
  { value: "GN", text: "Guin\u00e9e" },
  { value: "GQ", text: "Guin\u00e9e \u00e9quatoriale" },
  { value: "GW", text: "Guin\u00e9e-Bissau" },
  { value: "GY", text: "Guyana" },
  { value: "GF", text: "Guyane fran\u00e7aise" },
  { value: "HT", text: "Ha\u00efti" },
  { value: "HN", text: "Honduras" },
  { value: "HU", text: "Hongrie" },
  { value: "BV", text: "\u00cele Bouvet" },
  { value: "CX", text: "\u00cele Christmas" },
  { value: "CP", text: "\u00cele Clipperton" },
  { value: "AC", text: "\u00cele de l'Ascension" },
  { value: "IM", text: "\u00cele de Man" },
  { value: "NF", text: "\u00cele Norfolk" },
  { value: "AX", text: "\u00celes \u00c5land" },
  { value: "KY", text: "\u00celes Ca\u00efmans" },
  { value: "IC", text: "\u00celes Canaries" },
  { value: "CC", text: "\u00celes Cocos - Keeling" },
  { value: "CK", text: "\u00celes Cook" },
  { value: "FO", text: "\u00celes F\u00e9ro\u00e9" },
  { value: "HM", text: "\u00celes Heard et MacDonald" },
  { value: "FK", text: "\u00celes Malouines" },
  { value: "MP", text: "\u00celes Mariannes du Nord" },
  { value: "MH", text: "\u00celes Marshall" },
  {
    value: "UM",
    text: "\u00celes Mineures \u00c9loign\u00e9es des \u00c9tats-Unis",
  },
  { value: "SB", text: "\u00celes Salomon" },
  { value: "TC", text: "\u00celes Turks et Ca\u00efques" },
  { value: "VG", text: "\u00celes Vierges britanniques" },
  { value: "VI", text: "\u00celes Vierges des \u00c9tats-Unis" },
  { value: "IN", text: "Inde" },
  { value: "ID", text: "Indon\u00e9sie" },
  { value: "IQ", text: "Irak" },
  { value: "IR", text: "Iran" },
  { value: "IE", text: "Irlande" },
  { value: "IS", text: "Islande" },
  { value: "IL", text: "Isra\u00ebl" },
  { value: "IT", text: "Italie" },
  { value: "JM", text: "Jama\u00efque" },
  { value: "JP", text: "Japon" },
  { value: "JE", text: "Jersey" },
  { value: "JO", text: "Jordanie" },
  { value: "KZ", text: "Kazakhstan" },
  { value: "KE", text: "Kenya" },
  { value: "KG", text: "Kirghizistan" },
  { value: "KI", text: "Kiribati" },
  { value: "KW", text: "Kowe\u00eft" },
  { value: "LA", text: "Laos" },
  { value: "LS", text: "Lesotho" },
  { value: "LV", text: "Lettonie" },
  { value: "LB", text: "Liban" },
  { value: "LR", text: "Lib\u00e9ria" },
  { value: "LY", text: "Libye" },
  { value: "LI", text: "Liechtenstein" },
  { value: "LT", text: "Lituanie" },
  { value: "LU", text: "Luxembourg" },
  { value: "MK", text: "Mac\u00e9doine" },
  { value: "MG", text: "Madagascar" },
  { value: "MY", text: "Malaisie" },
  { value: "MW", text: "Malawi" },
  { value: "MV", text: "Maldives" },
  { value: "ML", text: "Mali" },
  { value: "MT", text: "Malte" },
  { value: "MA", text: "Maroc" },
  { value: "MQ", text: "Martinique" },
  { value: "MU", text: "Maurice" },
  { value: "MR", text: "Mauritanie" },
  { value: "YT", text: "Mayotte" },
  { value: "MX", text: "Mexique" },
  { value: "MD", text: "Moldavie" },
  { value: "MC", text: "Monaco" },
  { value: "MN", text: "Mongolie" },
  { value: "ME", text: "Mont\u00e9n\u00e9gro" },
  { value: "MS", text: "Montserrat" },
  { value: "MZ", text: "Mozambique" },
  { value: "MM", text: "Myanmar" },
  { value: "NA", text: "Namibie" },
  { value: "NR", text: "Nauru" },
  { value: "NP", text: "N\u00e9pal" },
  { value: "NI", text: "Nicaragua" },
  { value: "NE", text: "Niger" },
  { value: "NG", text: "Nig\u00e9ria" },
  { value: "NU", text: "Niue" },
  { value: "NO", text: "Norv\u00e8ge" },
  { value: "NC", text: "Nouvelle-Cal\u00e9donie" },
  { value: "NZ", text: "Nouvelle-Z\u00e9lande" },
  { value: "OM", text: "Oman" },
  { value: "UG", text: "Ouganda" },
  { value: "UZ", text: "Ouzb\u00e9kistan" },
  { value: "PK", text: "Pakistan" },
  { value: "PW", text: "Palaos" },
  { value: "PA", text: "Panama" },
  { value: "PG", text: "Papouasie-Nouvelle-Guin\u00e9e" },
  { value: "PY", text: "Paraguay" },
  { value: "NL", text: "Pays-Bas" },
  { value: "PE", text: "P\u00e9rou" },
  { value: "PH", text: "Philippines" },
  { value: "PN", text: "Pitcairn" },
  { value: "PL", text: "Pologne" },
  { value: "PF", text: "Polyn\u00e9sie fran\u00e7aise" },
  { value: "PR", text: "Porto Rico" },
  { value: "PT", text: "Portugal" },
  { value: "QA", text: "Qatar" },
  { value: "HK", text: "R.A.S. chinoise de Hong Kong" },
  { value: "MO", text: "R.A.S. chinoise de Macao" },
  {
    value: "QO",
    text: "r\u00e9gions \u00e9loign\u00e9es de l\u2019Oc\u00e9anie",
  },
  { value: "CF", text: "R\u00e9publique centrafricaine" },
  { value: "CD", text: "R\u00e9publique d\u00e9mocratique du Congo" },
  { value: "DO", text: "R\u00e9publique dominicaine" },
  { value: "CZ", text: "R\u00e9publique tch\u00e8que" },
  { value: "RE", text: "R\u00e9union" },
  { value: "RO", text: "Roumanie" },
  { value: "GB", text: "Royaume-Uni" },
  { value: "RU", text: "Russie" },
  { value: "RW", text: "Rwanda" },
  { value: "EH", text: "Sahara occidental" },
  { value: "BL", text: "Saint-Barth\u00e9l\u00e9my" },
  { value: "KN", text: "Saint-Kitts-et-Nevis" },
  { value: "SM", text: "Saint-Marin" },
  { value: "MF", text: "Saint-Martin" },
  { value: "PM", text: "Saint-Pierre-et-Miquelon" },
  { value: "VC", text: "Saint-Vincent-et-les Grenadines" },
  { value: "SH", text: "Sainte-H\u00e9l\u00e8ne" },
  { value: "LC", text: "Sainte-Lucie" },
  { value: "WS", text: "Samoa" },
  { value: "AS", text: "Samoa am\u00e9ricaines" },
  { value: "ST", text: "Sao Tom\u00e9-et-Principe" },
  { value: "SN", text: "S\u00e9n\u00e9gal" },
  { value: "RS", text: "Serbie" },
  { value: "CS", text: "Serbie-et-Mont\u00e9n\u00e9gro" },
  { value: "SC", text: "Seychelles" },
  { value: "SL", text: "Sierra Leone" },
  { value: "SG", text: "Singapour" },
  { value: "SK", text: "Slovaquie" },
  { value: "SI", text: "Slov\u00e9nie" },
  { value: "SO", text: "Somalie" },
  { value: "SD", text: "Soudan" },
  { value: "LK", text: "Sri Lanka" },
  { value: "SE", text: "Su\u00e8de" },
  { value: "CH", text: "Suisse" },
  { value: "SR", text: "Suriname" },
  { value: "SJ", text: "Svalbard et \u00cele Jan Mayen" },
  { value: "SZ", text: "Swaziland" },
  { value: "SY", text: "Syrie" },
  { value: "TJ", text: "Tadjikistan" },
  { value: "TW", text: "Ta\u00efwan" },
  { value: "TZ", text: "Tanzanie" },
  { value: "TD", text: "Tchad" },
  { value: "TF", text: "Terres australes fran\u00e7aises" },
  { value: "IO", text: "Territoire britannique de l'oc\u00e9an Indien" },
  { value: "PS", text: "Territoire palestinien" },
  { value: "TH", text: "Tha\u00eflande" },
  { value: "TL", text: "Timor oriental" },
  { value: "TG", text: "Togo" },
  { value: "TK", text: "Tokelau" },
  { value: "TO", text: "Tonga" },
  { value: "TT", text: "Trinit\u00e9-et-Tobago" },
  { value: "TA", text: "Tristan da Cunha" },
  { value: "TN", text: "Tunisie" },
  { value: "TM", text: "Turkm\u00e9nistan" },
  { value: "TR", text: "Turquie" },
  { value: "TV", text: "Tuvalu" },
  { value: "UA", text: "Ukraine" },
  { value: "EU", text: "Union europ\u00e9enne" },
  { value: "UY", text: "Uruguay" },
  { value: "VU", text: "Vanuatu" },
  { value: "VE", text: "Venezuela" },
  { value: "VN", text: "Vi\u00eat Nam" },
  { value: "WF", text: "Wallis-et-Futuna" },
  { value: "YE", text: "Y\u00e9men" },
  { value: "ZM", text: "Zambie" },
  { value: "ZW", text: "Zimbabwe" },
];
