import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";

import CircularProgress from "@material-ui/core/CircularProgress";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const CallbacksTable = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [callbacks, setCallbacks] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setColumns([
      {
        title: "Nom complet",
        field: "fullname",
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: "Email",
        field: "email",
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: "Téléphone",
        field: "phone",
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: "Etat",
        field: "done",
        render: data =>
          data.done ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => called(data.id)}
            >
              Fait
            </Button>
          ),
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      }
    ]);

    if (ability.can("list", "callbacks")) {
      getCallbacks();
    }
  }, []);

  const getCallbacks = () => {
    API.get("callbacks")
      .then(function(res) {
        setLoading(false);
        setCallbacks(res.data);
      })
      .catch(e => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const called = id => {
    if (ability.can("update", "callbacks")) {
      API.put("callbacks/" + id, { done: true })
        .then(() => {
          getCallbacks();
        })
        .catch(e => {
          dispatch({ type: "CATCH_ERROR", payload: e });
        });
    }
  };
  return (
    <>
      {ability.can("list", "callbacks") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto"
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              columns={columns}
              data={callbacks}
              defaultSorted={[
                {
                  id: "id",
                  desc: true
                }
              ]}
              options={{
                exportButton: true
              }}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                ))
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page"
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche"
                },
                header: {
                  actions: "Actions"
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter"
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder"
                  }
                }
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default CallbacksTable;
