import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Form from "forms/Manager";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const ManagersTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [managers, setManagers] = useState([]);
  const [actions, setActions] = useState([]);
  const [roles, setRoles] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (ability.can("list", "managers")) {
      API.get("managers")
        .then(function(res) {
          setLoading(false);
          setManagers(res.data.list);
          setColumns([
            {
              title: "Prénom",
              field: "fname",
              cellStyle: {
                width: 80,
                maxWidth: 80,
              },
              headerStyle: {
                width: 80,
                maxWidth: 80,
              },
            },
            {
              title: "Nom",
              field: "lname",
              cellStyle: {
                width: 80,
                maxWidth: 80,
              },
              headerStyle: {
                width: 80,
                maxWidth: 80,
              },
            },
            {
              title: "Email",
              field: "email",
              cellStyle: {
                width: 200,
                maxWidth: 200,
              },
              headerStyle: {
                width: 200,
                maxWidth: 200,
              },
            },
            {
              title: "Rôle",
              field: "roles",
              render: (data) =>
                res.data.roles.map((role, r) => {
                  if (data.roles.includes(role.id)) {
                    let name = role.name;
                    let nd = r == res.data.roles.length + 1 ? "" : ", ";
                    return name + nd;
                  }
                }),
              cellStyle: {
                width: 120,
                maxWidth: 120,
              },
              headerStyle: {
                width: 120,
                maxWidth: 120,
              },
            },
            {
              title: "Active",
              field: "active",
              render: (data) =>
                data.active ? (
                  <CheckIcon style={{ color: "green" }} />
                ) : (
                  <ClearIcon style={{ color: "red" }} />
                ),
              cellStyle: {
                width: 80,
                maxWidth: 80,
              },
              headerStyle: {
                width: 80,
                maxWidth: 80,
              },
            },
          ]);
          setRoles(res.data.roles);
        })
        .catch((e) => {
          dispatch({ type: "CATCH_ERROR", payload: e });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const classes = useStyles();

  const addItem = (event) => {
    if (ability.can("create", "managers")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouveau manager",
          content: (
            <Form action={"post"} roles={roles} onChange={createManager} />
          ),
        },
      });
    }
  };

  const createManager = (course) => {
    if (ability.can("create", "managers")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      let newManagers = JSON.parse(JSON.stringify(managers));
      newManagers.unshift(course);
      setManagers(newManagers);
    }
  };

  const updateManager = (manager) => {
    if (ability.can("update", "managers")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      let courseIndex = managers.findIndex((m) => m.id == manager.id);
      let newManagers = JSON.parse(JSON.stringify(managers));
      console.log("newManagers", newManagers);
      newManagers[courseIndex] = manager;

      setManagers(newManagers);
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "managers")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Modification des informations",
          content: (
            <Form
              action={"put"}
              manager={rowData}
              roles={roles}
              onChange={updateManager}
            />
          ),
        },
      });
    }
  };
  return (
    <>
      {ability.can("list", "managers") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              columns={columns}
              data={managers}
              defaultSorted={[
                {
                  id: "title",
                  desc: true,
                },
              ]}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    if (ability.can("delete", "managers")) {
                      API.delete("managers/" + oldData.id)
                        .then(() => {
                          setManagers(
                            managers.filter((m) => m.id !== oldData.id)
                          );
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    } else {
                      reject();
                    }
                  }),
              }}
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default ManagersTable;
