import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import './style.css'

const TextEditor = ({ value, handleChange }) => {
    const  modules  = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list:  "ordered" }, { list:  "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: 'justify' }, { align: '' }, { align: 'center' }, { align: 'right' }],
            [{ script:  "sub" }, { script:  "super" }],
            ["blockquote", "code-block"],
            ["link"],
            ["clean"],
        ],
        clipboard: {
            matchVisual: false
        }
    };
    return (
        <ReactQuill
            modules={modules}
            value={value}
            onChange={handleChange}
            placeholder="The content starts here..."
            theme="snow"
        />
    )
}
export default TextEditor;