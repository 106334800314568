import React, { useEffect, forwardRef, useState, useContext } from "react";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CircularProgress from "@material-ui/core/CircularProgress";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Form from "modules/sites/Site";
import API from "api";

const SitesTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [sites, setSites] = useState([]);
  const [payments, setPayments] = useState([]);

  const [loading, setLoading] = useState(false);

  const getList = () => {
    API.get("sites")
      .then(function(res) {
        setLoading(false);
        setPayments(res.data.payments);
        const pageLoad = Math.random()
          .toString(36)
          .substring(7);
        let list = res.data.sites.map((l) => ({
          ...l,
          img_url: "https://" + l.site + "/" + l.logo + "?" + pageLoad,
        }));

        setSites(list);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setColumns([
      {
        title: "Logo",
        field: "img_name",
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
        render: (data) => <img width="60px" src={data.img_url} />,
      },
      {
        title: "Site",
        field: "site",
        cellStyle: {
          width: 150,
          maxWidth: 150,
        },
        headerStyle: {
          width: 150,
          maxWidth: 150,
        },
        render: (data) => (
          <a target="_blank" href={`https://${data.site}`}>
            {`https://${data.site}`}
          </a>
        ),
      },
      {
        title: "Pays",
        field: "country",
        cellStyle: {
          width: 100,
          maxWidth: 100,
        },
        headerStyle: {
          width: 100,
          maxWidth: 100,
        },
      },
      {
        title: "Téléphone",
        field: "phone1",
        cellStyle: {
          width: 100,
          maxWidth: 100,
        },
        headerStyle: {
          width: 100,
          maxWidth: 100,
        },
      },
      {
        title: "Prix (%)",
        field: "price_percent",
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
        render: (data) => (
          <span>
            {data.percent_symbol}
            {data.price_percent}%
          </span>
        ),
      },
    ]);

    if (ability.can("list", "sites")) {
      getList();
    }
  }, []);

  const addItem = (event) => {
    if (ability.can("create", "sites")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouveau Site",
          content: <Form action={"post"} onChange={createELearning} />,
        },
      });
    }
  };

  const createELearning = () => {
    if (ability.can("create", "sites")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const updateSite = () => {
    if (ability.can("update", "sites")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "sites")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          width: "full",
          title: rowData.title,
          content: (
            <Form
              action={"put"}
              site={rowData}
              payments={payments}
              onChange={updateSite}
            />
          ),
        },
      });
    }
  };

  return (
    <>
      {ability.can("list", "sites") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              options={{ pageSize: 3, pageSizeOptions: [1, 3, 5, 10, 20] }}
              columns={columns}
              data={sites}
              defaultSorted={[
                {
                  id: "title",
                  desc: true,
                },
              ]}
              editable={
                ability.can("delete", "sites") && {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      console.log(oldData.id);
                      API.delete("sites/" + oldData.id)
                        .then(() => {
                          setSites(
                            sites.filter((row) => row.id !== oldData.id)
                          );
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    }),
                }
              }
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default SitesTable;
