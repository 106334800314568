import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";
import UploadIcon from "@material-ui/icons/Backup";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

export default function ImageUpload(props) {
  const [file, setFile] = React.useState(props.imagePreviewUrl);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.imagePreviewUrl || defaultImage
  );
  let fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    props.onChange(e.target.files[0]);
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();

    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {file === null ? (
          <UploadIcon
            style={{ cursor: "pointer" }}
            color="secondary"
            {...addButtonProps}
            onClick={() => handleClick()}
          />
        ) : (
          <span>
            <EditIcon
              style={{ cursor: "pointer" }}
              color="secondary"
              {...addButtonProps}
              onClick={() => handleClick()}
            />

            {avatar ? <br /> : null}
            <HighlightOffIcon
              style={{ cursor: "pointer" }}
              color="primary"
              {...removeButtonProps}
              onClick={() => handleRemove()}
            />
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
