import { Ability } from "@casl/ability";
import jwt from "jsonwebtoken";
import Cookie from "js-cookie";

let token = Cookie.get("tgo");
const decoded = jwt.decode(token);
console.log(decoded);
let ability_perms = [];
if (decoded) {
  if (decoded.admin) {
    ability_perms = [{ actions: "manage", subject: "all" }];
  } else {
    let perms = decoded.perms;
    ability_perms = Object.keys(perms).map(el => {
      let splitted_perms = perms[el].split(".");

      let subject = splitted_perms[0];
      let actions = splitted_perms[1];
      return { actions: actions, subject: subject };
    });
  }
}
export default new Ability(ability_perms);
