import React, { useContext } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Close from "@material-ui/icons/Close";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  fullwidth: {
    width: "85%"
  },
  close: {
    float: "right",
    cursor: "pointer",
    "&:hover": {
      color: "#c62828"
    }
  }
}));

const RightDrawerSecondChild = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);

  const closeDrawerSecondChild = () => {
    dispatch({ type: "CLOSE_DRAWER_SECOND_CHILD" });
  };

  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={state.drawerSecondChild}
      onClose={closeDrawerSecondChild}
      classes={{ paper: classes.fullwidth }}
    >
      <Container component="main">
        <Box mt={3} textAlign="left" component="h3" variant="h5">
          {state.drawerSecondChildTitle}
          <Close
            fontSize="large"
            className={classes.close}
            onClick={closeDrawerSecondChild}
          />
        </Box>

        {props.children}
        {props.ctxx}
      </Container>
    </Drawer>
  );
};

export default RightDrawerSecondChild;
