import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Cookie from "js-cookie";
import validations from "assets/js/validations";
import API from "api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "540px",
  },
  textField: {
    width: "100%",
  },
}));

const CourseCategoryForm = (props) => {
  const classes = useStyles();

  const { dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);

  const [action, setAction] = useState("post");
  const [category, setCategory] = useState({
    lang_iso: Cookie.get("lang") ?? "fr",
    title: "",
    meta_title: "",
    meta_description: "",
    permalink: "",
    description: "",
    active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setCategory(props.category);
  }, []);

  const handleChange = (event) => {
    setCategory({
      ...category,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckChange = (event) => {
    setCategory({
      ...category,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(props, action);
    let id = props.action === "put" ? "/" + category.id : "";
    const options = {
      method: props.action,
      data: { ...category },
      url: "eLearning/categories" + id,
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: { type: "success", content: content },
        });
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextValidator
            label="Titre"
            className={classes.textField}
            onChange={handleChange}
            name="title"
            variant="outlined"
            margin="dense"
            value={category.title}
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            label="Meta Titre"
            className={classes.textField}
            onChange={handleChange}
            name="meta_title"
            variant="outlined"
            margin="dense"
            value={category.meta_title}
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            label="Meta description"
            className={classes.textField}
            onChange={handleChange}
            name="meta_description"
            variant="outlined"
            margin="dense"
            value={category.meta_description}
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Slug (permalink)"
            onChange={handleChange}
            name="permalink"
            variant="outlined"
            margin="dense"
            value={category.permalink}
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Description"
            multiline
            rows="2"
            onChange={handleChange}
            name="description"
            variant="outlined"
            margin="dense"
            value={category.description}
            validators={["required"]}
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="active"
                checked={category.active}
                value={category.active}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Active."
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default CourseCategoryForm;
