import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import validations from "assets/js/validations";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const CourseProgramsParts = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setColumns([
      {
        title: "Titre",
        field: "title",
        editComponent: props => (
          <TextField
            type="text"
            style={{ width: "100%" }}
            multiline
            rows="2"
            value={props.value}
            variant="outlined"
            label="Titre"
            onChange={e => props.onChange(e.target.value)}
          />
        ),
        cellStyle: {
          width: 400,
          maxWidth: 400
        },
        headerStyle: {
          width: 400,
          maxWidth: 400
        }
      },
      {
        title: "Déscription",
        field: "description",
        editComponent: props => (
          <TextField
            type="text"
            multiline
            rows="2"
            style={{ width: "100%" }}
            value={props.value}
            variant="outlined"
            label="Déscription"
            onChange={e => props.onChange(e.target.value)}
          />
        ),
        cellStyle: {
          width: 400,
          maxWidth: 400
        },
        headerStyle: {
          width: 400,
          maxWidth: 400
        }
      },
      {
        title: "Jour",
        field: "day",
        editComponent: props => (
          <TextField
            type="text"
            multiline
            rows="2"
            value={props.value}
            variant="outlined"
            label="Jour"
            onChange={e => props.onChange(e.target.value)}
          />
        ),
        cellStyle: {
          width: 150,
          maxWidth: 150
        },
        headerStyle: {
          width: 150,
          maxWidth: 150
        }
      },
      {
        title: "Ordre",
        field: "rank",
        editComponent: props => (
          <TextField
            type="text"
            multiline
            rows="2"
            value={props.value}
            variant="outlined"
            label="Ordre"
            onChange={e => props.onChange(e.target.value)}
          />
        ),
        cellStyle: {
          width: 100,
          maxWidth: 100
        },
        headerStyle: {
          width: 100,
          maxWidth: 100
        }
      },
      {
        title: "Active",
        field: "active",
        initialEditValue: true,
        editComponent: props => (
          <Switch
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
            color="primary"
          />
        ),
        render: data =>
          data.active ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 80,
          maxWidth: 80
        },
        headerStyle: {
          width: 80,
          maxWidth: 80
        }
      }
    ]);
    setData(props.program.parts);
  }, []);

  return (
    <>
      {ability.can("list", "courseLessons") ? (
        <MaterialTable
          title={props.program.title}
          columns={columns}
          data={data}
          defaultSorted={[
            {
              id: "day",
              asc: true
            }
          ]}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                if (
                  newData.title !== "" &&
                  newData.description !== "" &&
                  newData.rank !== ""
                ) {
                  API.post("programs/" + props.program.id + "/parts", newData)
                    .then(res => {
                      const editedData = JSON.parse(JSON.stringify(data));
                      editedData.push(res.data);
                      setData(editedData);
                      resolve();
                    })
                    .catch(e => {
                      dispatch({ type: "CATCH_ERROR", payload: e });
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                } else {
                  reject();
                  alert(validations.allFieldsRequired);
                }
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if (
                  newData.title !== "" &&
                  newData.rank !== ""
                ) {
                  API.put("parts/" + oldData.id, newData)
                    .then(() => {
                      const editedData = JSON.parse(JSON.stringify(data));
                      const index = data.indexOf(oldData);
                      editedData[index] = newData;
                      setData(editedData);

                      resolve();
                    })
                    .catch(e => {
                      dispatch({ type: "CATCH_ERROR", payload: e });
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                } else {
                  reject();
                  alert(validations.allFieldsRequired);
                  /**
                    dispatch({
                      type: "OPEN_SNACKBAR",
                      payload: {
                        type: "error",
                        content: validations.allFieldsRequired
                      }
                    });
                    */
                }
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                API.delete("parts/" + oldData.id)
                  .then(() => {
                    setData(data.filter(row => row.id !== oldData.id));
                    resolve();
                  })
                  .catch(e => {
                    dispatch({ type: "CATCH_ERROR", payload: e });
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              })
          }}
          options={{
            paging: false
          }}
          icons={{
            SortArrow: forwardRef((props, ref) => (
              <KeyboardArrowDown {...props} ref={ref} />
            ))
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsSelect: "Lignes",
              labelRowsPerPage: "Ligne par page",
              firstAriaLabel: "Première page",
              firstTooltip: "Première page",
              previousAriaLabel: "Page précédente",
              previousTooltip: "Page précédente",
              nextAriaLabel: "Page suivante",
              nextTooltip: "Page suivante",
              lastAriaLabel: "Dernière page",
              lastTooltip: "Dernière page"
            },
            toolbar: {
              exportTitle: "Exporter",
              exportName: "Exporter en format CSV",
              searchTooltip: "Recherche",
              searchPlaceholder: "Recherche"
            },
            header: {
              actions: "Actions"
            },
            body: {
              addTooltip: "Ajouter",
              deleteTooltip: "Supprimer",
              editTooltip: "Modifier",
              emptyDataSourceMessage: "Aucun résultat trouvé",
              filterRow: {
                filterTooltip: "Filter"
              },
              editRow: {
                deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                cancelTooltip: "Annuler",
                saveTooltip: "Sauvegarder"
              }
            }
          }}
        />
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default CourseProgramsParts;
