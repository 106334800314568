import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";
import { TextField } from "@material-ui/core";

const containerCss = {
  root: {
    background: "#fff",
    padding: "10px 20px",
    borderRadius: 5,
  },
  item: {
    marginTop: 20,
  },
  day: {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: "Arial",
    marginBottom: 10,
  },
  actions: {
    display: "flex",
    marginTop: "20px",
  },
  image: {
    position: "relative",
  },
  imageDesktop: {
    margin: 1,
  },
  imageMobile: {
    margin: 1,
  },
  title: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  titleDesktop: {
    border: "1px solid #c8c4c4",
    background: "#d5d5d58f",
    padding: "3px 7px",
    borderRadius: "5px",
  },
  titleMobile: {
    marginLeft: 5,
    border: "1px solid #c8c4c4",
    background: "#d5d5d58f",
    padding: "3px 7px",
    borderRadius: "5px",
  },
  textField: {
    marginRight: 24,
    marginRight: 16,
    marginTop: 0,
    width: 301,
  },
  saveContainer: {
    paddingTop: 8,
    fontSize: 16,
    fontWeight: 400,
  },
  saveText: {
    marginTop: 8,
  },
  saveButton: {
    marginRight: 8,
  },
  input: {
    width: 25,
    height: 25,
    fontSize: 18,
    textAlign: "center",
    padding: 0,
    marginRight: 3,
  },
  rank: {
    margin: 8,
  },
};

const inputCss = {
  input: {
    display: "none",
  },
  upload: {
    marginRight: 20,
  },
};

const containerCssStyles = makeStyles(containerCss);
const inputCssStyles = makeStyles(inputCss);

const SlidesList = (props) => {
  const containerStyles = containerCssStyles();
  const inputStyles = inputCssStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [slides, setSlides] = useState([]);
  const [rankInput, setRankInput] = useState({});

  const [loading, setLoading] = useState(false);

  const getList = () => {
    API.get("slides")
      .then(function(res) {
        console.log("slides", res.data);
        let newRankInput = {};
        res.data.forEach((s, index) => {
          newRankInput[index] = false;
        });
        setRankInput(newRankInput);
        setSlides(res.data);
        setLoading(false);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (ability.can("list", "slides")) {
      getList();
    }
  }, []);

  const handleFileChange = (event, type, index) => {
    const image_name = type === "img_desktop" ? "desktop_file" : "mobile_file";
    let newSlides = [...slides];
    newSlides[index] = {
      ...newSlides[index],
      [type]: event.target.files[0],
      [image_name]: URL.createObjectURL(event.target.files[0]),
    };

    setSlides(newSlides);
  };

  const handleChangeText = (event, index) => {
    let newSlides = [...slides];
    newSlides[index] = {
      ...newSlides[index],
      [event.target.name]: event.target.value,
    };
    setSlides(newSlides);
  };

  const showRankInput = (i) => {
    console.log("index", i);
    console.log("rankInput", rankInput);
    setRankInput({ ...rankInput, [i]: true });
  };

  const hideRankInput = (i) => {
    setRankInput({ ...rankInput, [i]: false });
  };

  const saveSlides = async (event) => {
    event.preventDefault();
    await setLoading(true);

    for (const slide of slides) {
      try {
        const formData = await new FormData();
        if (slide.id) await formData.append("id", slide.id);
        await formData.append("desktop_file", slide.desktop_file || null);
        await formData.append("img_desktop", slide.img_desktop || null);
        await formData.append("mobile_file", slide.mobile_file || null);
        await formData.append("img_mobile", slide.img_mobile || null);
        await formData.append("img_link", slide.img_link);
        await formData.append("img_alt", slide.img_alt);
        await formData.append("rank", slide.rank);

        console.log("slide", slide);
        const options = await {
          method: "POST",
          data: formData,
          url: "slides",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        await API(options);
      } catch (e) {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      }
    }

    const content = validations.successfullyUpdated;

    await getList();
    await setLoading(false);
    await dispatch({
      type: "OPEN_SNACKBAR",
      payload: {
        type: "success",
        content,
      },
    });
  };

  const deleteSlide = (id) => {
    setLoading(true);
    API.delete("slides/" + id)
      .then(() => {
        getList();
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const newSlide = (id) => {
    let newSlides = [...slides];
    newSlides.unshift({
      img_desktop: `blank-desktop.jpg`,
      img_mobile: `blank-mobile.jpg`,
      img_alt: null,
      img_link: null,
      rank: 0,
    });
    setSlides(newSlides);
  };

  return (
    <>
      {ability.can("list", "slides") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <div className={containerStyles.root}>
              <div className={containerStyles.saveContainer}>
                <span className={containerStyles.saveButton}>
                  <AsyncButton
                    onClick={(e) => saveSlides(e)}
                    loading={loading}
                    title={"Sauvegarder"}
                    color="primary"
                    size="small"
                    disabled={loading}
                  ></AsyncButton>
                </span>
                <AsyncButton
                  onClick={(e) => newSlide(e)}
                  title={"Nouveau slide"}
                  color="secondary"
                  size="small"
                ></AsyncButton>
                <div className={containerStyles.saveText}>
                  Apres toute modification ou ajout cliquer sur ce button pour
                  Sauvegarder vos changement :{" "}
                </div>
              </div>

              {slides.map((banner, index) => (
                <div key={banner.id} className={containerStyles.item}>
                  <div className={containerStyles.day}>
                    {rankInput[index] ? (
                      <input
                        name="rank"
                        onChange={(event) => handleChangeText(event, index)}
                        value={banner.rank}
                        className={containerStyles.input}
                        onBlur={() => hideRankInput(index)}
                        autoFocus
                      />
                    ) : (
                      <span
                        className={containerStyles.rank}
                        onClick={() => showRankInput(index)}
                      >
                        {banner.rank}
                      </span>
                    )}
                    - {banner.img_alt}
                  </div>
                  <div className={containerStyles.image}>
                    <img
                      className={containerStyles.imageDesktop}
                      src={
                        banner.desktop_file
                          ? banner.desktop_file
                          : `${process.env.REACT_APP_API_PUBLIC_URL}images/slides/${banner.img_desktop}`
                      }
                      width="79%"
                    />

                    <img
                      className={containerStyles.imageMobile}
                      src={
                        banner.mobile_file
                          ? banner.mobile_file
                          : `${process.env.REACT_APP_API_PUBLIC_URL}images/slides/${banner.img_mobile}`
                      }
                      width="20%"
                    />
                    <div className={containerStyles.title}>
                      <span className={containerStyles.titleDesktop}>
                        {banner.desktop_file
                          ? banner.desktop_file
                          : banner.img_desktop}{" "}
                      </span>
                      <span className={containerStyles.titleMobile}>
                        {banner.mobile_file
                          ? banner.mobile_file
                          : banner.img_mobile}
                      </span>
                    </div>
                  </div>
                  <div className={containerStyles.actions}>
                    {!banner.id && (
                      <>
                        <input
                          key={`desktop-${banner.id}`}
                          accept="image/*"
                          className={inputStyles.input}
                          id={`contained-button-desktop-${banner.id}`}
                          type="file"
                          onChange={(e) =>
                            handleFileChange(e, "img_desktop", index)
                          }
                        />
                        <label
                          htmlFor={`contained-button-desktop-${banner.id}`}
                          className={inputStyles.upload}
                        >
                          <Button
                            variant="contained"
                            component="span"
                            color={"primary"}
                          >
                            Slide Desktop
                          </Button>
                        </label>

                        <input
                          key={`mobile-${banner.id}`}
                          accept="image/*"
                          className={inputStyles.input}
                          id={`contained-button-mobile-${banner.id}`}
                          type="file"
                          onChange={(e) =>
                            handleFileChange(e, "img_mobile", index)
                          }
                        />
                        <label
                          htmlFor={`contained-button-mobile-${banner.id}`}
                          className={inputStyles.upload}
                        >
                          <Button
                            variant="contained"
                            color={"primary"}
                            component="span"
                          >
                            Slide mobile
                          </Button>
                        </label>
                      </>
                    )}

                    <TextField
                      className={containerStyles.textField}
                      label="Lien du slide"
                      onChange={(event) => handleChangeText(event, index)}
                      name="img_link"
                      value={banner.img_link}
                      variant="outlined"
                      margin="dense"
                    />

                    <TextField
                      className={containerStyles.textField}
                      label="Nom du slide"
                      onChange={(event) => handleChangeText(event, index)}
                      name="img_alt"
                      value={banner.img_alt}
                      variant="outlined"
                      margin="dense"
                    />

                    {banner.id && (
                      <AsyncButton
                        onClick={() => deleteSlide(banner.id)}
                        title={"Supprimer"}
                        color="secondary"
                      ></AsyncButton>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default SlidesList;
