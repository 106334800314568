import React, { useEffect, forwardRef, useState, useContext } from "react";
import countries from "assets/js/countries";
import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EventIcon from "@material-ui/icons/Event";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswerOutlined";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import OfferFaq from "modules/offers/OfferFaq";
import OfferCandidate from "modules/offers/OfferCandidate";
import Form from "modules/offers/Offer";
import API from "api";

const OffersTable = (props) => {
  const { dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [offers, setOffers] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);

  const employmentTypes = [
    { id: "FULL_TIME", title: "CDI" },
    { id: "PART_TIME", title: "CDD" },
    { id: "CONTRACTOR", title: "Freelance" },
    { id: "TEMPORARY", title: "Stage" },
    { id: "INTERN", title: "Stage Pré-embauche" },
    { id: "VOLUNTEER", title: "Bénévole" },
    { id: "PER_DIEM", title: "Par jour" },
    { id: "OTHER", title: "Autre" },
  ];

  const jobLocations = [
    { id: "REMOTE", title: "À distance" },
    { id: "HYBRID", title: "Hybride" },
    { id: "OFFICE", title: "Bureau" },
  ];

  const salaryPeriods = [
    { id: "HOUR", title: "Par heure" },
    { id: "DAY", title: "Par jour" },
    { id: "WEEK", title: "Par semaine" },
    { id: "MONTH", title: "Par mois" },
    { id: "YEAR", title: "Par an" },
  ];

  const experiences = [
    { id: "BEGINNER", title: "Débutant" },
    { id: "JUNIOR", title: "Junior" },
    { id: "SENIOR", title: "Sénior" },
    { id: "EXPERT", title: "Expert" },
  ];

  const getCountry = (key) => {
    const countryObject = countries.filter((c) => c.value === key);
    return countryObject.length > 0 ? countryObject[0].text : "Maroc";
  };

  const getList = () => {
    API.get("offer")
      .then(function(res) {
        setLoading(false);
        const pageLoad = Math.random()
          .toString(36)
          .substring(7);
        let list = res.data.list.map((l) => ({
          ...l,
          img_url:
            process.env.REACT_APP_API_PUBLIC_URL +
            "images/job-offers/thumbs/" +
            l.img_name +
            "?" +
            pageLoad,
        }));
        setOffers(list);
        setCategories(res.data.categories);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setColumns([
      {
        title: "",
        field: "img_name",
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
        render: (data) => <img width="60px" src={data.img_url} />,
      },
      {
        title: "ID",
        field: "id",
        cellStyle: {
          width: 10,
          maxWidth: 10,
        },
        headerStyle: {
          width: 10,
          maxWidth: 10,
        },
      },
      {
        title: "Candidats",
        field: "candidates",
        render: (data) => (
          <IconButton
            onClick={() => openFullscreenDialog("candidates", data)}
            component="span"
          >
            <EventIcon />
          </IconButton>
        ),
        sorting: false,
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
      {
        title: "FAQs",
        sorting: false,
        render: (data) => (
          <IconButton
            onClick={() => openFullscreenDialog("faq", data)}
            component="span"
          >
            <QuestionAnswerIcon />
          </IconButton>
        ),
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
      {
        title: "Titre",
        field: "title",
        cellStyle: {
          width: 300,
          maxWidth: 300,
        },
        headerStyle: {
          width: 300,
          maxWidth: 300,
        },
      },
      {
        title: "Entreprise",
        field: "company",
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
      {
        title: "Pays",
        field: "job_country_iso",
        render: (data) => getCountry(data.job_country_iso),
        cellStyle: {
          width: 250,
          maxWidth: 250,
        },
        headerStyle: {
          width: 250,
          maxWidth: 250,
        },
      },
      {
        title: "Visites",
        field: "visits",
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
      {
        title: "Active",
        field: "active",
        initialEditValue: true,
        render: (data) =>
          data.active ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
    ]);

    if (ability.can("list", "offers")) {
      getList();
    }
  }, []);

  const addItem = (event) => {
    if (ability.can("create", "offers")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouvelle formation",
          content: (
            <Form
              action={"post"}
              categories={categories}
              onChange={createOffer}
              employmentTypes={employmentTypes}
              jobLocations={jobLocations}
              salaryPeriods={salaryPeriods}
              experiences={experiences}
            />
          ),
        },
      });
    }
  };

  const openFullscreenDialog = (section, offer) => {
    if (section === "faq" && ability.can("list", "offerFaq"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les faqs - " + offer.title,
          width: "full",
          content: <OfferFaq offer={offer} />,
        },
      });
    else if (section === "candidates" && ability.can("list", "offerCandidates"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les candidats de l'offre " + offer.title,
          width: "full",
          content: <OfferCandidate offer={offer} />,
        },
      });
  };

  const createOffer = () => {
    if (ability.can("create", "offers")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const updateOffer = () => {
    if (ability.can("update", "offers")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "offers")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          width: "full",
          title: rowData.title,
          content: (
            <Form
              action={"put"}
              offer={rowData}
              categories={categories}
              onChange={updateOffer}
              employmentTypes={employmentTypes}
              jobLocations={jobLocations}
              salaryPeriods={salaryPeriods}
              experiences={experiences}
            />
          ),
        },
      });
    }
  };

  return (
    <>
      {ability.can("list", "offers") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              options={{ pageSize: 3, pageSizeOptions: [1, 3, 5, 10, 20] }}
              columns={columns}
              data={offers}
              defaultSorted={[
                {
                  id: "title",
                  desc: true,
                },
              ]}
              editable={
                ability.can("delete", "offers") && {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      console.log(oldData.id);
                      API.delete("offer/" + oldData.id)
                        .then(() => {
                          setOffers(
                            offers.filter((row) => row.id !== oldData.id)
                          );
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    }),
                }
              }
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default OffersTable;
