import Cookie from "js-cookie";

const globalReducer = (state, action) => {
  switch (action.type) {
    // Auth
    case "LOGIN":
      let token = action.payload.token;
      if (!token) {
        return;
      }
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const parsedToken = JSON.parse(window.atob(base64));

      Cookie.set("tgo", token, { expires: 7 });

      return {
        ...state,
        isAuthenticated: true,
        user: parsedToken.user,
        token: Cookie.get("tgo"),
      };

    case "LOGOUT":
      Cookie.remove("tgo");

      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };

    // Dialog
    case "OPEN_DIALOG":
      //action.payload.event.preventDefault();
      //action.payload.event.stopPropagation();
      return {
        ...state,
        dialog: true,
        dialogTitle: action.payload.title,
        dialogWidth: action.payload.width,
        dialogContent: action.payload.content,
      };

    case "CLOSE_DIALOG":
      return {
        ...state,
        dialog: false,
        dialogTitle: null,
        dialogFullScreen: false,
        dialogContent: null,
      };

    // Drawer
    case "OPEN_DRAWER":
      //action.payload.event.preventDefault();
      //action.payload.event.stopPropagation();
      return {
        ...state,
        drawer: true,
        drawerTitle: action.payload.title,
        drawerWidth: action.payload.width,
        drawerContent: action.payload.content,
      };

    case "CLOSE_DRAWER":
      return {
        ...state,
        drawer: false,
        drawerTitle: null,
        drawerWidth: true,
        drawerFullScreen: false,
        drawerContent: null,
      };

    // drawerFirstChild
    case "OPEN_DRAWER_FIRST_CHILD":
      //action.payload.event.preventDefault();
      //action.payload.event.stopPropagation();
      return {
        ...state,
        drawerFirstChild: true,
        drawerFirstChildTitle: action.payload.title,
        drawerFirstChildWidth: action.payload.width,
        drawerFirstChildContent: action.payload.content,
      };

    case "CLOSE_DRAWER_FIRST_CHILD":
      return {
        ...state,
        drawerFirstChild: false,
        drawerFirstChildTitle: null,
        drawerFirstChildWidth: true,
        drawerFirstChildFullScreen: false,
        drawerFirstChildContent: null,
      };

    // drawerSecondChild
    case "OPEN_DRAWER_SECOND_CHILD":
      //action.payload.event.preventDefault();
      //action.payload.event.stopPropagation();
      return {
        ...state,
        drawerSecondChild: true,
        drawerSecondChildTitle: action.payload.title,
        drawerSecondChildWidth: action.payload.width,
        drawerSecondChildContent: action.payload.content,
      };

    case "CLOSE_DRAWER_SECOND_CHILD":
      return {
        ...state,
        drawerSecondChild: false,
        drawerSecondChildTitle: null,
        drawerSecondChildWidth: true,
        drawerSecondChildFullScreen: false,
        drawerSecondChildContent: null,
      };

    case "UPDATE_ME":
      return {
        ...state,
        user: action.payload,
      };

    case "SET_LANG":
      return {
        ...state,
        lang: action.payload,
      };

    // Snackbar dispatch({type: 'OPEN_SNACKBAR', payload:{type:'success', content:''}})
    case "OPEN_SNACKBAR":
      return {
        ...state,
        snackbar: true,
        snackbarType: action.payload.type,
        snackbarContent: action.payload.content,
      };

    case "CLOSE_SNACKBAR":
      return {
        ...state,
        snackbar: false,
        snackbarContent: null,
      };

    // Catch http errors
    case "CATCH_ERROR":
      let e = action.payload;
      const code = parseInt(e.response && e.response.status);
      let error = "";
      if (e.response && e.response.data && e.response.data.error) {
        error = e.response.data.error;
      }
      if (code) {
        if (code === 401) error = "Oups...Vous avez pas accès a ce module.";
        return {
          ...state,
          snackbar: true,
          snackbarType: "error",
          snackbarContent: error,
        };
      }

    default:
      return state;
  }
};

export default globalReducer;
