import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import MaterialTable from "material-table";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import validations from "assets/js/validations";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import TextEditor from "components/TextEditor/TextEditor";

import SimulatorAnswers from "modules/simulator/SimulatorAnswers";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";

const SimulatorQuestions = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [image, setImage] = useState([]);

  const [loading, setLoading] = useState(false);

  const onChangeImage = (file) => {
    setImage(file);
  };

  useEffect(() => {
    setLoading(true);
    setColumns([
      {
        title: "Question",
        field: "question",
        editComponent: (props) => (
          <TextEditor
            value={props.value}
            handleChange={props.onChange}
          />
        ),
        cellStyle: {
          width: 800,
          maxWidth: 800,
        },
        headerStyle: {
          width: 800,
          maxWidth: 800,
        },
      },
      {
        title: "Explication",
        field: "explanation",
        editComponent: (props) => (
          <TextEditor
            value={props.value}
            handleChange={props.onChange}
          />
        ),
        cellStyle: {
          width: 800,
          maxWidth: 800,
        },
        headerStyle: {
          width: 800,
          maxWidth: 800,
        },
      },
      {
        title: "Type",
        field: "type",
        lookup: {
          new: "Multiple",
          confirmed: "Unique",
        },
        initialEditValue: "unique",
        editComponent: (props) => (
          <TextField
            select
            variant="outlined"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            inputProps={{ name: "type" }}
          >
            <MenuItem value="multiple">Multiple</MenuItem>
            <MenuItem value="unique">unique</MenuItem>
          </TextField>
        ),
        render: (data) => (data.type === "unique" ? "Unique" : "Multiple"),
        cellStyle: {
          width: 120,
          maxWidth: 120,
        },
        headerStyle: {
          width: 120,
          maxWidth: 120,
        },
      },
      {
        title: "Ordre",
        field: "rank",
        editComponent: (props) => (
          <TextField
            type="text"
            style={{ width: "100%" }}
            value={props.value}
            variant="outlined"
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
        cellStyle: {
          width: 120,
          maxWidth: 120,
        },
        headerStyle: {
          width: 120,
          maxWidth: 120,
        },
      },
      {
        title: "Annexe",
        field: "img_name",
        render: (data) =>
          data.img_name ? (
            <img
              src={
                process.env.REACT_APP_API_PUBLIC_URL +
                "images/simulators/questions/" +
                data.img_name
              }
              width="50px"
            />
          ) : (
            "-"
          ),
        editComponent: (props) => (
          <ImageUpload
            imagePreviewUrl={
              props.value
                ? process.env.REACT_APP_API_PUBLIC_URL +
                  "images/simulators/questions/" +
                  props.value
                : null
            }
            onChange={(e) => onChangeImage(e)}
            addButtonProps={{
              color: "rose",
              round: true,
            }}
            changeButtonProps={{
              color: "rose",
              round: true,
            }}
            removeButtonProps={{
              color: "danger",
              round: true,
            }}
          />
        ),
        cellStyle: {
          width: 200,
          maxWidth: 200,
        },
        headerStyle: {
          width: 200,
          maxWidth: 200,
        },
      },
      {
        title: "Largeur",
        field: "img_width",
        editComponent: (props) => (
          <TextField
            type="text"
            style={{ width: "100%" }}
            value={props.value}
            variant="outlined"
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
        cellStyle: {
          width: 200,
          maxWidth: 200,
        },
        headerStyle: {
          width: 200,
          maxWidth: 200,
        },
      },
      {
        title: "Hauteur",
        field: "img_height",
        editComponent: (props) => (
          <TextField
            type="text"
            style={{ width: "100%" }}
            value={props.value}
            variant="outlined"
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
        cellStyle: {
          width: 200,
          maxWidth: 200,
        },
        headerStyle: {
          width: 200,
          maxWidth: 200,
        },
      },
      {
        title: "Active",
        field: "active",
        initialEditValue: true,
        render: (data) =>
          data.active ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        editComponent: (props) => (
          <Switch
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
            value={props.value}
            color="primary"
          />
        ),
        cellStyle: {
          width: 60,
          maxWidth: 60,
        },
        headerStyle: {
          width: 60,
          maxWidth: 60,
        },
      },
    ]);

    API.get("exercices/" + props.exercice + "/questions")
      .then(function(res) {
        setLoading(false);
        setData(res.data);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const openAnswersDialog = (question_id, title, type) => {
    dispatch({
      type: "OPEN_DRAWER_SECOND_CHILD",
      payload: {
        title: "Les Réponses - " + title + " (" + type + ")",
        width: "full",
        content: <SimulatorAnswers question={question_id} type={type} />,
      },
    });
  };

  return (
    <>
      {ability.can("list", "simulatorQuestions") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              columns={columns}
              data={data}
              defaultSorted={[
                {
                  id: "rank",
                  asc: true,
                },
              ]}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    if (
                      newData.type !== "" &&
                      newData.question !== "" &&
                      newData.rank !== "" &&
                      newData.active !== ""
                    ) {
                      const formData = new FormData();
                      formData.append("type", newData.type);
                      formData.append("question", newData.question);
                      formData.append("rank", newData.rank);
                      formData.append("active", newData.active);
                      formData.append("explanation", newData.explanation);
                      formData.append("image", image);
                      formData.append("img_width", newData.img_width);
                      formData.append("img_height", newData.img_height);

                      const options = {
                        method: "POST",
                        data: formData,
                        url: "exercices/" + props.exercice + "/questions",
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      };

                      API(options)
                        .then((res) => {
                          const editedData = JSON.parse(JSON.stringify(data));
                          editedData.push(res.data);
                          setData(editedData);
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    } else {
                      reject();
                      alert(validations.allFieldsRequired);
                    }
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    if (
                      newData.type !== "" &&
                      newData.question !== "" &&
                      newData.rank !== "" &&
                      newData.active !== ""
                    ) {
                      const formData = new FormData();
                      formData.append("type", newData.type);
                      formData.append("question", newData.question);
                      formData.append("rank", newData.rank);
                      formData.append("active", newData.active);
                      formData.append("explanation", newData.explanation);
                      formData.append("image", image);
                      formData.append("img_width", newData.img_width);
                      formData.append("img_height", newData.img_height);
                      formData.append("_method", "put");

                      const options = {
                        method: "POST",
                        data: formData,
                        url:
                          "exercices/" +
                          props.exercice +
                          "/questions/" +
                          oldData.id,
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      };

                      API(options)
                        .then((res) => {
                          const editedData = JSON.parse(JSON.stringify(data));
                          const index = data.indexOf(oldData);
                          newData.img_name = res.data.img_name;
                          editedData[index] = newData;
                          setData(editedData);

                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                        })
                        .finally(() => {
                          setLoading(false);
                        });
                    } else {
                      reject();
                      alert(validations.allFieldsRequired);
                      /**
                  dispatch({
                    type: "OPEN_SNACKBAR",
                    payload: {
                      type: "error",
                      content: validations.allFieldsRequired
                    }
                  });
                  */
                    }
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    API.delete(
                      "exercices/" + props.exercice + "/questions/" + oldData.id
                    )
                      .then(() => {
                        setData(data.filter((row) => row.id !== oldData.id));
                        resolve();
                      })
                      .catch((e) => {
                        dispatch({ type: "CATCH_ERROR", payload: e });
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }),
              }}
              options={{
                exportButton: true,
              }}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              actions={[
                {
                  icon: "question_answer",
                  tooltip: "Réponses",
                  onClick: (event, rowData) =>
                    openAnswersDialog(
                      rowData.id,
                      rowData.question,
                      rowData.type
                    ),
                },
              ]}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default SimulatorQuestions;
