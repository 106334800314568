import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";

import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    width: "100%",
  },
}));

const RegionForm = (props) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [region, setRegion] = useState({
    name: "",
    countries: [],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setRegion(props.region);
  }, []);

  const handleChange = (event) => {
    setRegion({
      ...region,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Update the formData object
    const formData = new FormData();

    for (var key in region) {
      formData.append(key, region[key]);
    }

    if (props.action === "put") {
      formData.append("_method", "put");
    }

    let id = props.action === "put" ? "/" + region.id : "";

    const options = {
      method: "POST",
      data: formData,
      url: "regions" + id,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content: content,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextValidator
            label="Region Name"
            className={classes.textField}
            onChange={handleChange}
            name="name"
            value={region.name}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectValidator
            className={classes.textField}
            multiple
            fullWidth
            label="Pays"
            name="countries"
            value={region.countries}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            SelectProps={{
              multiple: true,
            }}
            errorMessages={[validations.required]}
          >
            {props.countries.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>

        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default RegionForm;
